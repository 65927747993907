/**
 * @module core/session/actions
 */

const NAMESPACE = 'session';

const SESSION_SET_TOKEN = 'SESSION_SET_TOKEN';
const SESSION_END_SESSION = 'SESSION_END_SESSION';
const SESSION_SUSPEND_SESSION = 'SESSION_SUSPEND_SESSION';
const SESSION_SET_USER_PROFILE = 'SESSION_SET_USER_PROFILE';

export {
  NAMESPACE,
  SESSION_SET_TOKEN,
  SESSION_END_SESSION,
  SESSION_SUSPEND_SESSION,
  SESSION_SET_USER_PROFILE,
};
