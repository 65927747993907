/**
 * @module core/actions
 */

import { MOUNT_VIEW, UNMOUNT_VIEW } from './consts';

/**
 * Monta uma `view` (veja a documentação no reducer). Esta ação deve
 * ser despachada quando um componente é montado para que o estado
 * permaneça consistente durante a execução da aplicação.
 *
 * @param {String} applet id do `applet` ao qual a `view` pertence.
 * @param {String} view id da `view` a ser montada.
 */
function mountView(applet, view) {
  return {
    type: MOUNT_VIEW,
    applet,
    view,
  };
}

/**
 * Desmonta uma `view` (veja a documentação no reducer). Esta ação deve
 * ser despachada quando um componente é desmontado para que valores
 * que não serão mais utilizados possam ser removidos do Redux.
 *
 * @param {String} view id da `view` a ser desmontada.
 */
function unmountView(view) {
  return {
    type: UNMOUNT_VIEW,
    view,
  };
}

export { unmountView, mountView };
