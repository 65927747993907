import {
  CACHE_DELETE_DATA,
  CACHE_INVALIDATE,
  CACHE_SET_DATA,
  CACHE_UPDATE,
  NAMESPACE,
} from './consts';

/**
 * Vincula dados à um recurso e armazena-os na
 * view indicada.
 *
 * @param {string} view id da view onde serão armazenados os dados;
 * @param {string} resource url do recurso na API;
 * @param {any} data dados a serem armazenados;
 * @param {boolean} page se `true` os dados passados serão concatenados aos
 * que já existirem (caso existam) para o par (view, resource).
 * @returns {object} uma ação
 */
function set(view, resource, data, page = false, pagination) {
  return {
    namespace: NAMESPACE,
    type: CACHE_SET_DATA,
    resource,
    view,
    data,
    page,
    pagination,
  };
}

function update(view, resource, updateFn) {
  return {
    namespace: NAMESPACE,
    type: CACHE_UPDATE,
    updateFn,
    resource,
    view,
  };
}

/**
 * Marca os dados viculados aos recursos fornecidos salvos na
 * view indicada como inválidos.
 *
 * @param {string} view id da view onde serão armazenados os dados;
 * @param {...string} resources urls dos recursos na API;
 * @returns object
 */
function invalidate(view, ...resources) {
  return {
    namespace: NAMESPACE,
    type: CACHE_INVALIDATE,
    resources,
    view,
  };
}

/**
 * Desvincula os dados do recurso que estão armazenados na view.
 *
 * @param {string} view
 * @param {string} resource
 * @returns object
 */
function remove(view, resource) {
  return {
    namespace: NAMESPACE,
    type: CACHE_DELETE_DATA,
    resource,
    view,
  };
}

const actions = {
  invalidate,
  remove,
  update,
  set,
};

export default actions;
