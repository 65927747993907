const NAMESPACE = 'comm';

const COMM_SET_UPLOADING = 'COMM_SET_UPLOADING';
const COMM_SET_DOWNLOADING = 'COMM_SET_DOWNLOADING';
const COMM_SAVE_REQUEST = 'COMM_SAVE_REQUEST';
const COMM_SET_DEFERRED_REQUEST = 'COMM_SET_DEFERRED_REQUEST';
const COMM_SET_LAST_RESPONSE_SIZE = 'COMM_SET_LAST_RESPONSE_SIZE';

export {
  NAMESPACE,
  COMM_SAVE_REQUEST,
  COMM_SET_UPLOADING,
  COMM_SET_DOWNLOADING,
  COMM_SET_DEFERRED_REQUEST,
  COMM_SET_LAST_RESPONSE_SIZE,
};
