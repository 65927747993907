import { List } from 'immutable';
import {
  REPORT_POP_MESSAGE,
  REPORT_REMOVE_MESSAGE,
  REPORT_SHOW_MESSAGES,
} from './consts';

function showMessages(state, { messages }) {
  return state.updateIn(['report', 'messages'], List(), (ms) =>
    ms.concat(messages),
  );
}

function popMessage(state) {
  return state.updateIn(['report', 'messages'], List(), (ms) => ms.shift());
}

function removeMessage(state, { message }) {
  return state.updateIn(['report', 'messages'], List(), (ms) =>
    ms.filter((m) => m.get('id') !== message.get('id')),
  );
}

function reducer(state, action) {
  switch (action.type) {
    case REPORT_SHOW_MESSAGES:
      return showMessages(state, action);
    case REPORT_POP_MESSAGE:
      return popMessage(state, action);
    case REPORT_REMOVE_MESSAGE:
      return removeMessage(state, action);
    default:
      return state;
  }
}

export default reducer;
