const NAMESPACE = 'components.TABBED';

const TABBED_SET_TAB = 'TABBED_SET_TAB';
const TABBED_PREVIOUS = 'TABBED_PREVIOUS';
const TABBED_NEXT = 'TABBED_NEXT';
const TABBED_SET_TAB_STATUS = 'TABBED_SET_TAB_STATUS';
const TABBED_SET_CURRENT_TAB_STATUS = 'TABBED_SET_CURRENT_TAB_STATUS';
const TABBED_SET_TAB_COUNT = 'TABBED_SET_TAB_COUNT';
const TABBED_RESET = 'TABBED_RESET';

export {
  NAMESPACE,
  TABBED_NEXT,
  TABBED_RESET,
  TABBED_SET_TAB,
  TABBED_PREVIOUS,
  TABBED_SET_TAB_COUNT,
  TABBED_SET_TAB_STATUS,
  TABBED_SET_CURRENT_TAB_STATUS,
};
