import { Record } from 'immutable';

const MessageType = Object.freeze({
  NOTICE: 'notice',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
});

const Message = Record({
  id: null,
  type: null,
  message: null,
});

export { Message, MessageType };
