import { Map, List } from 'immutable';

const defaultFormState = Map({
  // Estado referente aos campos
  data: Map(),
  types: Map(),
  focus: Map(),
  values: Map(),
  errors: Map(),
  fieldsSteps: List(),
  // Valores padrão
  defaultData: Map(),
  defaultValues: Map(),
  // Estado interno do formulário
  validator: () => Map(),
  showErrors: false,
  disabled: false,
  multifieldCount: Map(),
  // Validação vinda do servido
  remoteValidations: Map(),
});

export default defaultFormState;
