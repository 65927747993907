import { Request } from '.';
import { API_URL } from '../config';
import { applyURLVars } from './api';
import qsession from '../session/queries';
import { COMBOS_VIEW } from '../cache/consts';

function isTalkingToServer(state, direction, view, resource) {
  if (view && resource) {
    return Boolean(state.getIn(['comm', direction, view, resource]));
  }
  if (view) {
    const q = state.getIn(['comm', direction, view]);
    return Boolean(q && q.size > 0);
  }

  const q = state.getIn(['comm', direction]);
  return Boolean(q && q.size > 0);
}

function isLoadingCombo(state, combo) {
  return isTalkingToServer(state, 'isLoading', COMBOS_VIEW, combo);
}

function isLoading(state, view, resource) {
  return isTalkingToServer(state, 'isLoading', view, resource);
}

function isSending(state, view, resource) {
  return isTalkingToServer(state, 'isSending', view, resource);
}

function isCommunicating(state, view) {
  return isLoading(state, view, null) || isSending(state, view, null);
}

function isCommunicatingBut(state, view, resource) {
  const all = isLoading(state, view, null) || isSending(state, view, null);
  const one =
    isLoading(state, view, resource) || isSending(state, view, resource);
  return all && !one;
}

function retrieveSavedRequest(state, method, request) {
  const view = request.get('view');
  const resource = request.get('url');
  return state.getIn(['views', view, 'comm', method, resource]) || Request();
}

function getLastResponseSize(state, view, resource) {
  return state.getIn(['views', view, 'comm', 'lastResponseSize', resource]);
}

function getDownloadURLFor(state, url, params) {
  const token = qsession.getToken(state);
  return applyURLVars(
    `${API_URL}/${url}?_token=${token}&_broker=CORUJA`,
    params,
  );
}

const queries = {
  isLoading,
  isSending,
  isLoadingCombo,
  isCommunicating,
  getDownloadURLFor,
  isCommunicatingBut,
  getLastResponseSize,
  retrieveSavedRequest,
};

export default queries;
