const NAMESPACE = 'report';

const REPORT_SHOW_MESSAGES = 'REPORT_SHOW_MESSAGE';

const REPORT_POP_MESSAGE = 'REPORT_POP_MESSAGE';

const REPORT_REMOVE_MESSAGE = 'REPORT_REMOVE_MESSAGE';

export {
  NAMESPACE,
  REPORT_SHOW_MESSAGES,
  REPORT_POP_MESSAGE,
  REPORT_REMOVE_MESSAGE,
};
