import { List } from 'immutable';
import {
  NAMESPACE,
  REPORT_POP_MESSAGE,
  REPORT_REMOVE_MESSAGE,
  REPORT_SHOW_MESSAGES,
} from './consts';

function showMessage(message) {
  return {
    namespace: NAMESPACE,
    type: REPORT_SHOW_MESSAGES,
    messages: List([message]),
  };
}

function showMessages(messages) {
  return {
    namespace: NAMESPACE,
    type: REPORT_SHOW_MESSAGES,
    messages,
  };
}

function popMessage() {
  return {
    namespace: NAMESPACE,
    type: REPORT_POP_MESSAGE,
  };
}

function removeMessage(message) {
  return {
    namespace: NAMESPACE,
    type: REPORT_REMOVE_MESSAGE,
    message,
  };
}

const actions = {
  removeMessage,
  showMessages,
  showMessage,
  popMessage,
};

export default actions;
