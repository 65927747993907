const NAMESPACE = 'components.FORM';

const FORM_VALIDATE = 'FORM_VALIDATE';
const FORM_CLEAN = 'FORM_CLEAN';
const FORM_SET_VALUES = 'FORM_SET_VALUES';
const FORM_MERGE = 'FORM_MERGE';
const FORM_SET_REMOTE_VALIDATIONS = 'FORM_SET_REMOTE_VALIDATIONS';
const FORM_SET_SUBMIT_STATE = 'FORM_SET_SUBMIT_STATE';
const FORM_SET_FIELD_STATE = 'FORM_SET_FIELD_STATE';
const FORM_SET_FIELD_TYPE = 'FORM_SET_FIELD_TYPE';
const FORM_COPY_FIELD_DATA = 'FORM_COPY_FIELD_DATA';
const FORM_SET_FIELD_STEP = 'FORM_SET_FIELD_STEP';
const FORM_INIT_FIELD = 'FORM_INIT_FIELD';
const FORM_SET_FIELD_FOCUS = 'FORM_SET_FIELD_FOCUS';
const FORM_ADD_MULTIFIELD_ROW = 'FORM_ADD_MULTIFIELD_ROW';
const FORM_REMOVE_MULTIFIELD_ROW = 'FORM_REMOVE_MULTIFIELD_ROW';
const FORM_SET_VALIDATOR = 'FORM_SET_VALIDATOR';
const FORM_SHOW_ERRORS = 'FORM_SHOW_ERRORS';
const FORM_FORCE_VALIDATE = 'FORM_FORCE_VALIDATE';
const FORM_MERGE_FORMS = 'FORM_MERGE_FORMS';

export {
  NAMESPACE,
  FORM_VALIDATE,
  FORM_CLEAN,
  FORM_SET_VALUES,
  FORM_MERGE,
  FORM_SET_REMOTE_VALIDATIONS,
  FORM_SET_SUBMIT_STATE,
  FORM_SET_FIELD_STATE,
  FORM_SET_FIELD_TYPE,
  FORM_COPY_FIELD_DATA,
  FORM_SET_FIELD_STEP,
  FORM_INIT_FIELD,
  FORM_SET_FIELD_FOCUS,
  FORM_ADD_MULTIFIELD_ROW,
  FORM_REMOVE_MULTIFIELD_ROW,
  FORM_SET_VALIDATOR,
  FORM_SHOW_ERRORS,
  FORM_FORCE_VALIDATE,
  FORM_MERGE_FORMS,
};
