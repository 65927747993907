/**
 * @module core/util/actions
 */
import { Map } from 'immutable';
import { NAMESPACE, UTIL_DELETE_TIMEOUT, UTIL_SAVE_TIMEOUT } from './consts';
import qutil from './queries';

/**
 * Salvar um temporizador em uma `view`. Ele será identificado por um
 * `id` (para que possa ser resgatado usando `getTimeout`) fornecido
 * pelo usuário da função. Além disso deve-se fornecer o `Map timeout`
 * que contém as chaves `id`, que o valor retornado por `setTimeout`
 * (por exemplo) e uma função que será chamada caso o temporizador seja
 * cancelado.
 *
 * @param {String} view o `id` da view onde será salvo.
 * @param {String} id o `id` do temporizador.
 * @param {Map} timeout contém o identificador (retornado por setTimout)
 * e uma função de cancelamento.
 * @returns {Object} uma nova ação.
 */
function saveTimeout(view, id, timeout) {
  return {
    namespace: NAMESPACE,
    type: UTIL_SAVE_TIMEOUT,
    timeout,
    view,
    id,
  };
}

/**
 * Remove um temporizador que fora salvo com {@link saveTimeout}.
 *
 * @see {@link saveTimeout}
 *
 * @param {String} view o `id` da view onde será salvo.
 * @param {String} id o `id` do temporizador.
 * @returns {Object} uma nova ação.
 */
function deleteTimeout(view, id) {
  return {
    namespace: NAMESPACE,
    type: UTIL_DELETE_TIMEOUT,
    view,
    id,
  };
}

/**
 * Despacha uma ação depois da quantidade de tempo fornecida.
 *
 * @param {Object|Function} action ação a ser despachada com atraso.
 * @param {Number} milliseconds tempo em milissegundos.
 * @returns {Object} uma nova ação.
 */
function dispatchLater(action, milliseconds) {
  return (dispatch) => {
    const id = setTimeout(() => dispatch(action), milliseconds);
    return [id, () => clearTimeout(id)];
  };
}

/**
 * Despacha uma ação depois da quantidade de tempo fornecida. Caso
 * esta ação seja chamada novamente com os mesmo valores de `view`
 * e `id` e a chamada anterior não tenha resolvido ainda, ou seja,
 * foi chamada novamente antes do tempo configurado na chamada
 * anterior tenha se passado, a ação salva será cancela e esta
 * ficará como a vigente.
 *
 * Esta ação é útil para chamadas como a de buscar no servidor algum
 * conteúdo baseado no que o usuário digita, para que não seja feita
 * uma chamada toda vez que o usuário digitar algum caractere. Neste
 * caso a requisição à API só será feita depois de algum tempo que o
 * usuário parar de digitar.
 *
 * @param {String} view `id` da `view` onde será salvo o temporizador (veja a
 * função `saveTimeout`).
 * @param {String} id como o usuário da função identifica este `timeout`.
 * @param {Object|Function} action a ação a ser despachada com atraso.
 * @param {Number} milliseconds tempo de atraso.
 */
function dispatchUniqueLater(view, id, action, milliseconds) {
  return (dispatch, getState) => {
    // verifica se já existe um timeout salvo e cancela-o
    const saved = qutil.getTimeout(getState(), view, id);
    if (saved) {
      const cancel = saved.get('cancel');
      cancel();
    }

    const thunk = dispatchLater(action, milliseconds);
    const [timeoutID, cancel] = thunk(dispatch, getState);
    const cancelFn = () => {
      dispatch(deleteTimeout(view, id));
      cancel();
    };
    dispatch(saveTimeout(view, id, Map({ id: timeoutID, cancel: cancelFn })));
  };
}

const actions = {
  dispatchUniqueLater,
  dispatchLater,
  deleteTimeout,
  saveTimeout,
};

export default actions;
