import moment from 'moment';
import { Map } from 'immutable';

const defaultState = Map({
  tab: null,
  lastOnesOption: null,
  date: moment().endOf('day'),
  endDate: moment().startOf('day'),
});

export default defaultState;
