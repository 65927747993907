const NAMESPACE = 'cache';

const CACHE_SET_DATA = 'CACHE_SET_DATA';

const CACHE_DELETE_DATA = 'CACHE_DELETE_DATA';

const CACHE_INVALIDATE = 'CACHE_INVALIDATE';

const CACHE_UPDATE = 'CACHE_UPDATE';

const COMBOS_VIEW = '/suaformatura/coruja/comm/cache/combos/view';

export {
  NAMESPACE,
  CACHE_UPDATE,
  CACHE_SET_DATA,
  CACHE_INVALIDATE,
  CACHE_DELETE_DATA,
  COMBOS_VIEW,
};
