import { Map } from 'immutable';
import tableDefaultState from './db';
import {
  DATATABLE_SET_DETAILED_TUPLE,
  DATATABLE_SET_ACTIONS_ANCHOR,
  DATATABLE_SET_SELECTED_ROWS,
  DATATABLE_SET_PAGE_SIZE,
  DATATABLE_SET_SORTING,
  DATATABLE_TOGGLE_ROW,
  DATATABLE_SET_PAGE,
  DATATABLE_CLEAR,
} from './consts';

function setDetailedTuple(tableState, { tuple }) {
  return tableState.set('detailedTuple', tuple);
}

function setSorting(tableState, { key }) {
  const sorting = tableState.get('sorting');
  const oppositOf = { asc: 'desc', desc: 'asc' };
  const currentDir = sorting.get('direction');
  const currentKey = sorting.get('key');

  const newSorting = Map({
    direction: key === currentKey ? oppositOf[currentDir] : 'desc',
    key,
  });
  return tableState.set('sorting', newSorting);
}

function setSelectedRows(tableState, { rows }) {
  return tableState.set('selectedRows', rows);
}

function toggleRow(tableState, { id, tuple }) {
  const selected = tableState.get('selectedRows');

  let result = selected;
  if (selected.get(id)) {
    result = selected.remove(id);
  } else {
    result = selected.set(id, tuple);
  }

  return tableState.set('selectedRows', result);
}

function setPage(tableState, { page }) {
  return tableState.set('page', page);
}

function setPageSize(tableState, { size }) {
  return tableState.set('pageSize', size);
}

function setActionsAnchor(tableState, { anchor, tuple }) {
  return tableState.set('anchoredTuple', tuple).set('actionsAnchor', anchor);
}

function clear() {
  return tableDefaultState;
}

const updaters = {
  [DATATABLE_CLEAR]: clear,
  [DATATABLE_SET_PAGE]: setPage,
  [DATATABLE_TOGGLE_ROW]: toggleRow,
  [DATATABLE_SET_SORTING]: setSorting,
  [DATATABLE_SET_PAGE_SIZE]: setPageSize,
  [DATATABLE_SET_SELECTED_ROWS]: setSelectedRows,
  [DATATABLE_SET_DETAILED_TUPLE]: setDetailedTuple,
  [DATATABLE_SET_ACTIONS_ANCHOR]: setActionsAnchor,
};

function reducer(state, action) {
  const updater = updaters[action.type];
  return state.updateIn(
    ['views', action.view, 'tables', action.table],
    tableDefaultState,
    (tableState) => updater(tableState, action),
  );
}

export default reducer;
