import React from 'react';
import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Route } from 'react-router-dom';
import Section from '../../components/Section';

function Page404(props) {
  const { location } = props;
  return (
    <div style={{ maxWidth: '600px', margin: '24px auto' }}>
      <Section>
        <div style={{ maxWidth: '600px', margin: '24px auto' }}>
          <Typography color="secondary" align="center">
            <Warning style={{ fontSize: '50' }} />
          </Typography>
          <Typography variant="h6" color="primary" align="center">
            Erro 404!
          </Typography>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            align="center"
            style={{ marginTop: '24px' }}
          >
            Página{' '}
            <i>
              <b>{location.pathname}</b>
            </i>{' '}
            não encontrada.
          </Typography>
        </div>
      </Section>
    </div>
  );
}

const Erro404 = <Route component={Page404} />;

export default Erro404;
