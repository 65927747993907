/**
 * @module core/util
 */
import Immutable, { List, Map, isImmutable } from "immutable";
import moment from "moment";
import { bindActionCreators } from "redux";
import { chars, reverse, titleize } from "underscore.string";

// /////////////////////////////////////////////////////////
//
// Caracteres
//
// /////////////////////////////////////////////////////////

const digits = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
};

/**
 * Retorna **true** se a `string c` só tem um caractere e
 * ele é um dígito (0-9).
 *
 * @param {String} c uma `string`
 * @returns {Boolean} **true** se a `string` passada for um caractere.
 */
function isDigit(c) {
  return c.length === 1 && digits[c];
}

/**
 * Retorna **true** se a `string s` é composta unicamente
 * de dígitos.
 *
 * @param {String} s uma `string`.
 * @returns {Boolean} **true** se a `string` é composta unicamente de dígitos.
 */
function isDigitString(s) {
  let res = typeof s === "string";
  for (let i = 0; i < s.length && res; i += 1) {
    res = res && s.charAt(i);
  }

  return res;
}

/**
 * Retorna uma `string` com apenas os digitos presentes na
 * `string s.
 *
 * @param {String} s uma string que contenha dígitos.
 * @returns {String} que contenha o dígitos de `s` na ordem que aparecem.
 */
function keepDigits(s) {
  return chars(s).filter(isDigit).join("");
}

/**
 * TODO: REMOVER
 */
function getRecordValue(record, key, innerKey = null) {
  const obj = isImmutable(record) ? record.get(key) : record[key];
  const type = typeof obj;

  if (type === "number" || type === "string") {
    return obj;
  }
  if (isImmutable(obj)) {
    return obj.get("codigo") || obj.get("value") || obj.get(innerKey);
  }
  if (type === "object") {
    return obj.codigo || obj.value || obj[innerKey];
  }
  return obj;
}

// ---------------------------------------------------------
//
// String
//
// ---------------------------------------------------------

function removeStringAccents(s) {
  if (!s) {
    return s;
  }
  return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function createTupleStringfier(fields = []) {
  return (tuple) => {
    const mfn = (f) => {
      if (Array.isArray(f)) {
        return tuple.getIn(f) || "";
      }
      return tuple.get(f) || "";
    };
    return fields.map(mfn).join(" ");
  };
}

// /////////////////////////////////////////////////////////
//
// MISC
//
// /////////////////////////////////////////////////////////

/**
 * Retorna **true** se `x` é diferente de `null, undefined`
 * e `false`.
 *
 * @param {any} x um valor qualquer
 * @returns {Boolean} **true** se `x` é diferente de `null,
 * undefined` e `false`.
 */
function some(x) {
  return Boolean(x !== null && x !== undefined);
}

/**
 * Não utilize esta função
 */
// eslint-disable-next-line camelcase
function ATROCITY_some(x, includeFalse = true) {
  return Boolean(x !== null && x !== undefined && (includeFalse ? x !== false : true));
}

/**
 * Retorna `fn(x)` se o teste `some(x)` for verdadeiro ou
 * `notFoundValue` caso o teste falhe.
 *
 * @param {any} x valor no qual `fn` deve ser aplicada.
 * @param {Function} fn função a ser aplicada em `fn`.
 * @param {any} notFoundValue valor retornado se o teste falhar.
 * @returns {any} `fn(x)` ou `notFoundValue`.
 */
function onSome(x, fn, notFoundValue = null) {
  return some(x) ? fn(x) : notFoundValue;
}

/**
 * Retona `x` se o teste `some(x)` for  verdadeiro e `y` caso
 * o teste falhe.
 *
 * @param {any} x um valor qualquer.
 * @param {any} y um valor qualquer.
 * @returns {any} `x` ou `y`.
 */
function either(x, y = null) {
  return some(x) ? x : y;
}

/**
 * Retorna um 'array`de `size` elementos cujo primeiro valor é
 * `init` e cada valor subsequente obedeve a regra:
 *
 * ```js
 *     v[i] = v[i - 1] + pass
 * ```
 *
 * Exemplos:
 *
 * ```j
 *     range(5); // retornará [0, 1, 2, 3, 4]
 * ```
 *
 * ```js
 *     range(4, 2, 2).map(n => <div key={n}>{n}</div>);
 *     // <div>2</div>
 *     // <div>4</div>
 *     // <div>6</div>
 *     // <div>8</div>
 * ```
 *
 * @param {Number} size tamanho do `array` gerado.
 * @param {Number} init primeiro índice gerado.
 * @param {Number} pass tamanho passo de um índice para outro.
 * @returns {Array} de números
 */
function range(size, init = null, pass = null) {
  const r = [...Array(size).keys()];
  // TODO tirar esses 'null' nojentos daí
  if (init && pass) {
    return r.map((x, i) => x + init + i * pass);
  }
  if (init) {
    return r.map((x) => x + init);
  }
  return r;
}

// /////////////////////////////////////////////////////////
//
// Set object
//
// /////////////////////////////////////////////////////////

/**
 * Retorna um objeto que tem as chaves `keys` passadas à
 * função é o valor associoando à elas igual à **true**.
 *
 * É uma opção mais idiomática à `[].includes(...)`.
 *
 * Exemplo:
 *
 * ```js
 *     // { hello: true, world: true }
 *     const set = createSetObject('hello', 'world');
 *
 *     if (set[word]) {
 *       console.log('Hello world');
 *     }
 * ```
 *
 * @param {...String} keys um conjunto de chaves.
 * @returns {Object} que tem cada chave passada associada ao
 * valor **true**.
 */
function createSetObject(...keys) {
  const s = {};

  for (let i = 0; i < keys.length; i += 1) {
    s[keys[i]] = true;
  }
  return s;
}

function createSetPredicate(possibleValues = []) {
  if (!possibleValues || possibleValues.length < 1) {
    throw new Error("um predicado de conjunto não deve ser vazio");
  }
  const set = createSetObject(...possibleValues);
  return (key) => set[key];
}

// /////////////////////////////////////////////////////////
//
// Cache/combos
//
// /////////////////////////////////////////////////////////

/**
 * Recebe uma `List` de `Map`s e retorna retorna uma `List`
 * que cada valor é uma cópia de cada `Map` mais as chaves
 * `value` e `label`.
 *
 * Essas chaves são geradas segundos as regras:
 * * `out.get('value') === in.get('codigo')`
 * * `out.get('label') === in.get('nome') || in.get('descricao')`
 *
 * Esta função é utilizada para transforma lista de valores
 * enviadas pela API em um formato padrão de campos de formulário
 * do tipo combo: `select`, `multiselect`, `checkbox`, etc.
 *
 * @param {List} values uma `List` de `Map`s.
 * @returns {List} uma combo.
 */
function processCombo(values) {
  return values.map((v) => {
    const id = v.get("codigo");
    const label = v.get("nome", v.get("descricao"));
    return v.set("value", id).set("label", titleize(label));
  });
}

/**
 * Retorna o `Map` de `combo` cuja chave `codigo` tenha o valor
 * associado igual à `id`.
 *
 * @param {String|Number} id o `codigo` e alguma tupla de um combo.
 * @param {List} combo uma `List` de `Map`s.
 * @returns {Map} um dos `Map`s presentes em `combo`.
 */
function findInCombo(id, combo) {
  if (!id || !combo) {
    return null;
  }
  return combo.find((element) => element.get("codigo") === id);
}

// /////////////////////////////////////////////////////////
//
// Conversão de dados
//
// /////////////////////////////////////////////////////////

/**
 * Transforma a `String s` em um valor do tipo `type`. O valore
 * retornado pode ser uma `String` sem formatação, um número ou
 * uma `String` com formatação de data.
 *
 * Tipos:
 * * rg: transforma uma `String` com formatação de RG em uma `String`
 * que só contém os dígitos;
 * * cpf: transforma uma `String` com formatação de CPF em uma `String`
 * que só contém os dígitos;
 * * cep: transforma uma `String` com formatação de CEP em uma `String`
 * que só contém os dígitos;
 * * currency: transforma uma `String` com formatação de número em um número;
 * * percentage: transforma uma `String` com formatação de porcentagem
 * em um número;
 * * phone: transforma uma `String` com formatação de telefone em uma
 * `String`;
 * * date: transforma uma `String` com formatação de data em uma `String`
 * com formatação (a que é aceita e enviada pela API) padrão de data.
 *
 * @param {String} type tipo do valor na qual `s` será transformada.
 * @param {String|Number} s o valor que será transformada.
 * @returns {any} o valor de `s` transformado ou `s` se:
 * `s` é um valor equivalente valor à `false` em JavaScript
 * ou é `type` não for uma das opções acima
 * ou `s` é um número.
 */
function stringToData(type, s) {
  if (!s) {
    return s === "" ? null : s;
  }

  const stype = typeof s;
  switch (type) {
    case "rg":
      if (stype === "number") {
        return s;
      }
      return keepDigits(s);
    case "cpf":
      if (stype === "number") {
        return s;
      }
      return keepDigits(s).substring(0, 11);
    case "cgc":
    case "cnpj":
      if (stype === "number") {
        return s;
      }
      return keepDigits(s).substring(0, 14);
    case "cep":
      if (stype === "number") {
        return s;
      }
      return keepDigits(s).substring(0, 8);
    case "currency":
      if (stype === "number") {
        return s;
      }
      if (s.length === 1) {
        return Number(`0.${s}`);
      }
      return Number(keepDigits(s)) / 100;
    case "percentage":
      if (stype === "number") {
        return s;
      }
      return Number(s);
    case "phone":
      if (stype === "number") {
        return s;
      }
      return keepDigits(s).substring(0, 11);
    case "date":
      return moment(s).format("YYYY-MM-DDTHH:mm:ssZ");
    default:
      return s;
  }
}

/**
 * Recebe `values` que pode ser um `Array` ou um `Map` e multiselect `Boolean`
 * e retorna retorna um `List` caso o campo seja `multiselect` e um `Number`
 * se for `autocomplete`.
Cada valor corresponde ao código do(s) item(s) selecionado(s) nos campos:
 * `autocomplete` e `multiselect`.
 *
 * Esta função é utilizada para transformar a lista de valores
 * enviadas para API em um formato aceitável para os campos de formulário
 * do tipo: `autocomplete` e `multiselect`.
 *
 * @param {List} values um `Array` ou `Map`.
 * @returns {List} um `List` ou `Number`.
 *
 *
 */

function processAutocomplete(values, multiselect = false, field = "codigo") {
  if (List.isList(values)) {
    return values.map((item) => item.get("value") || item.get(field));
  }
  if (!Map.isMap(values)) {
    const data = Immutable.fromJS(values.map((item) => item[field] || item.value));
    return data.size <= 1 && !multiselect ? data.first() : data;
  }
  return values.get(field);
}

// /////////////////////////////////////////////////////////
//
// Immutable
//
// /////////////////////////////////////////////////////////

// Converte um array de arrays de smash em
// um objeto cujas chaves são os primeiros
// valores dos arrays internos e os valores
// são esses arrays.
function smashArrayToObject(keyPaths) {
  const obj = {};
  for (let i = 0; i < keyPaths.length; i++) {
    const kp = keyPaths[i];
    if (kp) {
      const first = kp[0];
      obj[first] = kp;
    }
  }
  return obj;
}

/**
 * Substitui as estruturas aninhas de `coll` por um de seus valores
 * internos. Essa função é semelhante à uma de achatamento (flatten)
 * em que se pode selecionar as chaves das estruturas aninhadas.
 *
 * Exemplo:
 *     const m = Map({
 *       tipo: Map({
 *         codigo: 10,
 *         descricao: 'Telefone Celular'
 *       }),
 *       respostas: List(['sim', 'não']),
 *     });
 *
 *     smash(m, ['tipo', 'descricao'], ['respostas', 1]);
 *     // => Map({ tipo: 'Telefone Celular', respostas: 'não' })
 *
 * @param {Map} coll estrutura a ser achatada.
 * @param {...Array} keyPaths lista variável de parâmetros que guiam o
 * processo de achatamento.
 * @returns {Map} achatado.
 */
function smash(coll, ...keyPaths) {
  if (!coll) {
    return coll;
  }

  const so = smashArrayToObject(keyPaths);
  return coll.map((v, k) => {
    const smashArray = so[k];
    if (!smashArray) {
      return v;
    }
    return v.getIn(smashArray.slice(1));
  });
}

// TODO avaliar a possibilidade de deletar essa função.
/**
 * Renameia as chaves de um `Map` segundo o objeto `changes`.
 *
 * <p
 *   style="background-color: red; color: white; font-weight: bold; width: 100%"
 * >
 *   AVALIAR A POSSIBILIDADE DE DELETAR ESSA FUNÇÃO.
 * </p>
 *
 * @param {Map} m `Map` a ter as chaves renomeadas.
 * @param {Object} changes um objeto que mapeia as chaves atuais em
 * seus novos nomes.
 * @param {bool} dismissNoMatch se `true`, entradas cujas chaves não
 * estiverem em `changes` não serão postas no `Map` retornado.
 * @returns {Map} que uma cópia de `m` com as chaves renomeadas.
 */
function renameKeys(m, changes, dismissNoMatch) {
  return m.mapEntries(([k, v]) => {
    const newKey = changes[k];
    if (dismissNoMatch && !newKey) {
      return null;
    }
    return [newKey || k, v];
  });
}

/**
 * Insere a função `fn` dentro do campo `dispatch` de um objeto
 * `record` do tipo `Request`, `Response` ou `RequiredValidation`.
 *
 * @param {Response} response um objeto.
 * @param {Function} fn um `action creator`.
 * @returns {Response} com `fn` no campo `dispatch`
 */
function insertInDispatch(record, fn) {
  if (!record || !fn) {
    return record;
  }

  const dispatchActions = record.get("dispatch");
  // Caso não haja dispatch.
  if (!dispatchActions) {
    return record.set("dispatch", List([fn]));
  }
  // Caso o dispatch tenha uma função.
  if (typeof dispatchActions === "function") {
    return record.set("dispatch", List([dispatchActions, fn]));
  }

  // Caso o dispatch no onSuccess seja uma instância de List.
  return record.set("dispatch", dispatchActions.push(fn));
}

/**
 * Insere a função `fn` dentro do `dispatch` do `onSuccess`
 * de um submit.
 *
 * @param {Submit} submit um objeto.
 * @param {function} fn um `action creator`.
 * @returns {Submit}.
 */
function insertInOnSuccess(submit, fn) {
  if (!submit || !fn) {
    return submit;
  }

  const onSuccessResponse = submit.getIn(["request", "onSuccess"]);
  return submit.setIn(["request", "onSuccess"], insertInDispatch(onSuccessResponse, fn));
}

// /////////////////////////////////////////////////////////
//
// Máscara
//
// /////////////////////////////////////////////////////////

/**
 * Retorna uma cópia de `s`. Se o tamnho de `s` for maior
 * ou igual à `maxSize`, então a `String` retonanda será
 * `s.substring(0, n)` com "..." no final.
 *
 * @param {String} s
 * @param {Number} maxSize
 * @returns {String}.
 */
function ellipsis(s, maxSize) {
  if (!s) {
    return null;
  }
  if (!maxSize || s.length <= maxSize) {
    return s;
  }

  const n = maxSize - 3;
  return `${s.substring(0, n)}...`;
}

function onlyZeros(s) {
  let res = true;

  for (let i = 0; i < s.length && res; i += 1) {
    res = res && s.charAt(i) === "0";
  }

  return res;
}

// Retorna a string com um separador a cada n caracteres.
//
// @param {string} s string a ser formatada
// @param {string} separator separador
// @param {number} chunkSize numero de caracteres antes do separador
function maskNumericLeft(s, separator, chunkSize) {
  // inverte a string.
  let remain = reverse(s);
  const chunks = [];

  while (remain.length > 0) {
    // pega os ultimos "n" caracteres e coloca em "chunks".
    const ch = reverse(remain.substring(0, chunkSize));
    chunks.push(ch);
    // pega os restos que sobraram da string.
    remain = remain.substring(chunkSize, remain.length);
  }
  // Reverte o array e adiciona um separador entre os valores.
  return chunks.reverse().join(separator);
}

// Insere separadores em um string.
//
// os ultimos digitos são separados pelo separador de direita.
// a cada 'rightSize' digitos a string é separada pelo
// separador de esquerda.
//
// @param {String} s string a ser separada
// @param {Number} leftSize tamanho de digitos da esquerda
// @param {Number} rightSize tamanho de digitos da direita
// @param {Number} leftSep separador da esquerda
// @param {Number} rightSep separador da direita
function maskNumeric(s, leftSize, rightSize, leftSep, rightSep) {
  // pega o o começo da string até o right size
  const count = s.length > 1 ? s.length - rightSize : 0;
  const leftStr = s.substring(0, count);

  // Coloca um separador nos últimos números
  const left = maskNumericLeft(leftStr, leftSep, leftSize);
  const right = s.substring(count, s.length);
  if (left.length < 1 && right.length < 1) {
    return "";
  }
  return [left, right].join(rightSep);
}

// É importante destacar nesta função que ela só gera valores
// absolutos, ou seja, `-13000.58` gerará a string mascarada
// `13.000,58`. Este comportamente é esperado, pois torna a
// exibição do valor mais flexível.
function currencyMask(val) {
  if (!some(val) || val === "") {
    return "0,00";
  }
  const value = typeof val === "number" ? val.toFixed(2) : val;
  if (onlyZeros(value)) {
    return "0";
  }
  const v = value.charAt(0) === "0" ? value.slice(1) : value;
  const s = keepDigits(v);
  const money = maskNumeric(s, 3, 2, ".", ",");
  if (money.charAt(0) === ",") {
    return `0${money}`;
  }
  return money;
}

function cpfMask(value) {
  if (!some(value)) {
    return "";
  }
  const ds = keepDigits(value).substring(0, 11);
  if (ds.length < 4) {
    return ds;
  }
  return maskNumeric(ds, 3, 2, ".", "-");
}

function paymentSlipMask(slipNumber) {
  if (!some(slipNumber)) {
    return "";
  }

  if (slipNumber.length !== 47) {
    return "Número de boleto inválido";
  }
  const part1 = slipNumber.slice(0, 5);
  const part2 = slipNumber.slice(5, 10);
  const part3 = slipNumber.slice(10, 15);
  const part4 = slipNumber.slice(15, 21);
  const part5 = slipNumber.slice(21, 26);
  const part6 = slipNumber.slice(26, 32);
  const part7 = slipNumber.slice(32, 33);
  const part8 = slipNumber.slice(33, 47);

  return `${part1}.${part2} ${part3}.${part4} ${part5}.${part6} ${part7} ${part8}`;
}

function percentMask(value) {
  if (!some(value)) {
    return "";
  }

  const porcentagem = (value * 100).toFixed(2); // Arredonda para 2 casas decimais
  return `${porcentagem} %`;
}

function cepMask(value) {
  if (!value) {
    return "";
  }
  const cep = keepDigits(value).substring(0, 8);
  if (cep.length < 2) {
    return cep;
  }
  return maskNumeric(cep, 99, 3, ".", "-");
}

// Máscara para formatação de números de telefone.
//
// @param {string} value
function phoneMask(value) {
  if (!value) {
    return "";
  }
  // Retira o que não for digito e retorna no máximo 11 número.
  const ps = keepDigits(value).substring(0, 11);
  if (ps.length < 3) {
    return ps;
  }
  const ddd = `(${ps.charAt(0)}${ps.charAt(1)})`;
  return `${ddd} ${maskNumeric(ps.substring(2), 5, 4, ".", "-")}`;
}

const dateFormat = {
  time: "HH:mm",
  date: "DD/MM/YYYY",
  month: "MM/YYYY",
  datetime: "DD/MM/YYYY [às] HH:mm",
};

/**
 * Formata o objeto `value` em uma String segundo os tipos:
 * * date
 * * month
 * * datetime
 *
 * @param {String} type tipo da máscara/formatação.
 * @param {*} value valor a ser formatado.
 * @returns {String} com `value` formatado.
 */
function format(type, value = null) {
  if (value) {
    if (typeof value === "string") {
      return moment(value).format(dateFormat[type]);
    }
    if (moment.isMoment(value)) {
      return value.format(dateFormat[type]);
    }
  }

  return "";
}

function cnpjMask(value) {
  if (!value) {
    return "";
  }
  const cd = keepDigits(value).substring(0, 14);
  if (cd.length < 3) {
    return cd;
  }
  const left = cd.substring(0, cd.length - 2);
  const right = cd.substring(cd.length - 2, cd.length);
  return `${maskNumeric(left, 3, 4, ".", "/")}-${right}`;
}

function cgcMask(value) {
  if (!some(value)) {
    return "";
  }

  const digits = keepDigits(value);
  if (digits.length <= 11) {
    return cpfMask(digits);
  }
  return cnpjMask(digits);
}

function datetimeMask() {
  return [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    "à",
    "s",
    " ",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
  ];
}

function dateMask() {
  return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
}

function dateMonthMask() {
  return [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
}

function bankAccountMask(value) {
  if (!value) {
    return "";
  }
  const size = value.length;
  const left = value.substring(0, size - 1);
  const right = value.substring(size - 1, size);

  return `${left}-${right}`;
}

const masks = {
  cpf: cpfMask,
  cep: cepMask,
  cgc: cgcMask,
  cnpj: cnpjMask,
  date: dateMask,
  phone: phoneMask,
  month: dateMonthMask,
  currency: currencyMask,
  datetime: datetimeMask,
  bankaccount: bankAccountMask,
  paymentSlip: paymentSlipMask,
  percent: percentMask,
};

const isTextMask = createSetObject(
  "text",
  "cnpj",
  "select",
  "number",
  "select",
  "password",
  "date",
  "datetime",
);

/**
 * Formata o objeto `value` em uma String segundo os tipos:
 * * cpf
 * * cep
 * * cnpj
 * * cgc
 * * phone
 * * currency
 * * datetime
 * * bankaccount
 * * date
 * * month
 * * paymentSlip
 *
 * @param {String} type tipo da máscara/formatação.
 * @param {*} value valor a ser formatado.
 * @returns {String} com `value` formatado.
 */
function mask(type, value) {
  const m = masks[type];
  if (m) {
    return m(value);
  }
  if (!some(value) && isTextMask[type]) {
    return "";
  }
  return value;
}

/**
 * @param {*} name Passa uma string, com o nome de um usuário,
 *  e a função separa as duas primeiras letras de cada nome,
 *  caso só tenha um, fica uma letra
 * @returns
 */
export function stringAvatar(name) {
  const uperName = name.toUpperCase();

  if (name.split(" ")[1]) {
    return {
      children: `${uperName.split(" ")[0][0]}${uperName.split(" ")[1][0]}`,
    };
  }
  return {
    children: `${uperName.split(" ")[0][0]}`,
  };
}

export function firstLetter(name) {
  const uperName = name.toUpperCase();

  return {
    children: `${uperName.split(" ")[0][0]}`,
  };
}

// /////////////////////////////////////////////////////////
//
// Redux
//
// /////////////////////////////////////////////////////////

/**
 * Versão com mais funcionalidades da `bindActionCreators` do
 * Redux em que além de serem passadas os `action creators`
 * normais, passa-se os `action creators` com nome `subscribe`
 * que são executados, por padrão, pelo componente `View` depois
 * que ele é montado.
 *
 * @param {Object} actionCreators
 * @param {Object} subscribes
 * @param {Function} dispatch
 * @returns {Object}.
 */
function bindActionCreatorsAndSubscribes(actionCreators, subscribes, dispatch) {
  let boundActions = {};
  if (actionCreators) {
    boundActions = bindActionCreators(actionCreators, dispatch);
  }
  if (!subscribes) {
    return boundActions;
  }
  return {
    subscribe: bindActionCreators(subscribes, dispatch),
    ...boundActions,
  };
}

// /////////////////////////////////////////////////////////
//
// Regex
//
// /////////////////////////////////////////////////////////

/**
 * Retorna uma expressão regular que tem correspondência em qualquer
 * `String` que tenha palavras (separadas por espaço em branco) de
 * `s`.
 *
 * Um exemplo de uso dessa função é para criar a RegExp de busca
 * por texto na `TableView`.
 *
 * @param {String} s frase de busca.
 * @param {String} flags passadas ao construtor RegExp`.
 * @returns {RegExp} de busca.
 */
function searchRegex(s, flags) {
  if (!s) {
    return null;
  }
  const x = s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return new RegExp(`.*${x.split(" ").join(".*")}.*`, flags);
}

// ---------------------------------------------------------
//
// Validação
//
// ---------------------------------------------------------

function basicMultifieldValidator(multifield, multiError) {
  if (!multifield || multifield.size < 1) {
    return List([Map(multiError)]);
  }
  let hasError = false;
  let errors = List([]);
  const keys = Object.keys(multiError);
  // Caso só haja um campo, ele pode ser todo vazio ou todo preenchido.
  if (multifield.size === 1) {
    const element = multifield.get(0);
    let hasValue = false;
    let hasNoValue = false;
    keys.forEach((k) => {
      if (some(element.get(k)) && element.get(k) !== "") {
        hasValue = true;
      } else {
        hasNoValue = true;
      }
    });
    if (hasValue) {
      if (hasNoValue) {
        hasError = true;
      }
      // eslint-disable-next-line no-use-before-define
      errors = errors.push(basicValidator(element, multiError));
    }
  }
  // Caso haja mais de um campo, todos devem estar preenchidos.
  else {
    multifield.forEach((element) => {
      keys.forEach((k) => {
        if (!some(element.get(k)) || element.get(k) === "") {
          hasError = true;
        }
      });
      // eslint-disable-next-line no-use-before-define
      errors = errors.push(basicValidator(element, multiError));
    });
  }
  return hasError ? errors : List();
}

/**
 * Verifica se os campos (indicados pelas chaves de `checkers`)
 * e retorna uma `Map` de erros.
 *
 * @param {Map} data dados do formulário.
 *
 * @param {Object} checkers objeto que mapeia o nome do campo
 * à uma mensagem de erro ou nome de uma `multifield` num
 * objeto que mapeia o nome dos campos [do multifield] à uma
 * mensagem de erro.
 */
function basicValidator(data, checkers) {
  const errs = {};
  const keys = Object.keys(checkers);
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const v = data.get(k);

    /*
      if (!data.has(k)) {
        Console.warn(`Campo ${k} não existe no formulário`);
      }
    */

    if (!some(v) || v === "" || (Immutable.isOrderedSet(v) && v.isEmpty())) {
      const e = checkers[k];
      if (typeof e === "object") {
        errs[k] = List([Map(e)]);
      } else {
        errs[k] = e;
      }
    } else if (Immutable.isList(v)) {
      errs[k] = basicMultifieldValidator(v, checkers[k]);
    }
  }

  return Map(errs);
}

// /////////////////////////////////////////////////////////
//
// Exportação
//
// /////////////////////////////////////////////////////////

export {
  // eslint-disable-next-line camelcase
  ATROCITY_some,
  bindActionCreatorsAndSubscribes,
  createTupleStringfier,
  removeStringAccents,
  processAutocomplete,
  createSetPredicate,
  insertInOnSuccess,
  insertInDispatch,
  createSetObject,
  getRecordValue,
  basicValidator,
  isDigitString,
  processCombo,
  stringToData,
  searchRegex,
  findInCombo,
  keepDigits,
  renameKeys,
  ellipsis,
  isDigit,
  either,
  format,
  onSome,
  smash,
  range,
  mask,
  some,
};
