/**
 * @module core/session/actions
 */

import cookies from 'js-cookie';
import qsession from './queries';
import {
  SESSION_SET_USER_PROFILE,
  SESSION_SUSPEND_SESSION,
  SESSION_END_SESSION,
  SESSION_SET_TOKEN,
  NAMESPACE,
} from './consts';

/**
 * Salva o `token` de sessão no Redux e no `cookie` "token".
 *
 * @param {String} token um `token` de sessão enviado pela API.
 * @returns {Object} uma nova ação.
 */
function setToken(token) {
  return {
    namespace: NAMESPACE,
    type: SESSION_SET_TOKEN,
    token,
  };
}

/**
 * Configura o estado da sessão como `SessionStates.ENDED`.
 *
 * @returns {Object} uma nova ação.
 */
function end() {
  return {
    namespace: NAMESPACE,
    type: SESSION_END_SESSION,
  };
}

/**
 * Configura o estado da sessão como `SessionStates.SUSPENDED`.
 *
 * @returns {Object} uma nova ação.
 */
function suspend() {
  return {
    namespace: NAMESPACE,
    type: SESSION_SUSPEND_SESSION,
  };
}

/**
 * Salva um novo `token` no `cookie` "token". Esta função é
 * utilizada para fazer mudança de organização.
 *
 * A cada nova requisição à API a aplicação (coruja) recebe
 * um novo `token`, contudo ele só é salvo em memória RAM
 * (no Redux), pois é o suficente para o funcionamento.
 *
 * Todavia, o processo de configuração consiste em informar
 * a API da troca de organização e recarregar a página *web*
 * (veja `window.location.replace`) para que o servidor envie
 * a aplicação (coruja; arquivo JavaScript) correspondentes
 * ao tipo da nova organização e o perfil do usuário naquela
 * organização.
 *
 * [Thunk*](https://github.com/reduxjs/redux-thunk).
 *
 * @returns {Function} um nova ação assíncrona (thunk*).
 */
function persistToken(token = null) {
  return (dispatch, getState) => {
    const value = token || qsession.getToken(getState());
    cookies.set('token', value);
  };
}

/**
 * Salva no Redux os dados de sessão e perfil enviados pela API no
 * momento em que o usuário abre a aplicação/*site*.
 *
 * @param {Map} profile dados de sessão e perfil enviados pela API.
 * @returns {Object} uma nova ação.
 */
function setUserProfile(profile) {
  return {
    namespace: NAMESPACE,
    type: SESSION_SET_USER_PROFILE,
    profile,
  };
}

const actions = {
  setUserProfile,
  persistToken,
  setToken,
  suspend,
  end,
};

export default actions;
