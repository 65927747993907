/**
 * @module core/storage/actions
 */
const NAMESPACE = 'storage';

const STORAGE_SET = 'STORAGE_SET';

const STORAGE_UPDATE = 'STORAGE_UPDATE';

export { STORAGE_SET, STORAGE_UPDATE, NAMESPACE };
