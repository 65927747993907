import { fromJS, Map } from 'immutable';

const tableDefaultState = fromJS({
  page: 0,
  pageSize: 10,
  sorting: {
    key: null,
    direction: null,
  },
  detailedTuple: null, // código da tupla detalhada
  selectedRows: Map(),
  anchoredTuple: null,
  actionsAnchor: null,
});

export default tableDefaultState;
