const NAMESPACE = 'components.datatable';

const DATATABLE_CLEAR = 'DATATABLE_CLEAR';
const DATATABLE_SET_PAGE = 'DATATABLE_SET_PAGE';
const DATATABLE_TOGGLE_ROW = 'DATATABLE_TOGGLE_ROW';
const DATATABLE_SET_SORTING = 'DATATABLE_SET_SORTING';
const DATATABLE_SET_PAGE_SIZE = 'DATATABLE_SET_PAGE_SIZE';
const DATATABLE_SET_SELECTED_ROWS = 'DATATABLE_SET_SELECTED_ROWS';
const DATATABLE_SET_DETAILED_TUPLE = 'DATATABLE_SET_DETAILED_TUPLE';
const DATATABLE_SET_ACTIONS_ANCHOR = 'DATATABLE_SET_ACTIONS_ANCHOR';

export {
  NAMESPACE,
  DATATABLE_CLEAR,
  DATATABLE_SET_PAGE,
  DATATABLE_TOGGLE_ROW,
  DATATABLE_SET_SORTING,
  DATATABLE_SET_PAGE_SIZE,
  DATATABLE_SET_DETAILED_TUPLE,
  DATATABLE_SET_SELECTED_ROWS,
  DATATABLE_SET_ACTIONS_ANCHOR,
};
