/**
 * @module core/session/reducer
 */

import cookies from 'js-cookie';
import SessionStates from './session-states';
import {
  SESSION_SET_USER_PROFILE,
  SESSION_SUSPEND_SESSION,
  SESSION_END_SESSION,
  SESSION_SET_TOKEN,
} from './consts';

/**
 * Função que aplica as ações do `session` no estado do `redux`.
 *
 * @param {Map} state o estado do `Redux`
 * @param {Object} action uma ação do `session` a ser aplicada no estado.
 * @returns {Map} um `Map` que é uma cópia de `state` com a ação aplicada.
 */
function reducer(state, { token, profile, type }) {
  switch (type) {
    case SESSION_SET_TOKEN:
      cookies.set('token', token);
      return state.setIn(['session', 'token'], token);
    case SESSION_END_SESSION:
      return state.setIn(['session', 'state'], SessionStates.ENDED);
    case SESSION_SUSPEND_SESSION:
      return state.setIn(['session', 'state'], SessionStates.SUSPENDED);
    case SESSION_SET_USER_PROFILE:
      return state.setIn(['session', 'profile'], profile);
    default:
      return state;
  }
}

export default reducer;
