/**
 * @module core/db
 */
import { Map, List } from 'immutable';
import defaultPipeState from './pipe/db';

/**
 * Descritor do estado de uma `view`. Nenhuma outra chave deve ser
 * adicionada ao estado da `view` sem que seja listada neste modelo.
 */
const defaultViewState = Map({
  // Valores salvos pelos subsistema 'storage'.
  storage: Map(),
  // Valores de controle e estado salvos pelos
  // componentes de formulário.
  forms: Map(),
  // Valores de controle e estado salvos pelos
  // componentes de tabela.
  tables: Map(),
});

/**
 * Descritor do estado da aplicação. Nenhuma outra chave deve ser
 * adicionada sem que seja listada neste modelo.
 */
const defaultDB = Map({
  // estado dos subsistemas
  comm: Map(),
  report: Map(),
  session: Map(),
  workspace: Map(),
  pipe: defaultPipeState,
  // estado das views
  currentView: '/',
  views: Map(),
  // estado dos applets
  currentApplet: List(['/']),
});

export { defaultViewState, defaultDB };
