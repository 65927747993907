/**
 * @module core/storage/reducer
 */

import { STORAGE_SET, STORAGE_UPDATE } from './consts';

/**
 * Função que aplica as ações do `storage` no estado do `redux`.
 *
 * @param {Map} state o estado do `Redux`
 * @param {Object} action uma ação do `storage` a ser aplicada no estado.
 * @returns {Map} um `Map` que é uma cópia de `state` com a ação aplicada.
 */
function reducer(state, { type, view, keyPath, value, updater }) {
  const kp = ['views', view, 'storage', ...keyPath];
  switch (type) {
    case STORAGE_SET:
      return state.setIn(kp, value);
    case STORAGE_UPDATE:
      return state.updateIn(kp, updater);
    default:
      return state;
  }
}

export default reducer;
