import { Set, List, Map } from 'immutable';
import { either, onSome, some, stringToData } from '../../util';
import {
  FORM_SET_REMOTE_VALIDATIONS,
  FORM_REMOVE_MULTIFIELD_ROW,
  FORM_ADD_MULTIFIELD_ROW,
  FORM_SET_SUBMIT_STATE,
  FORM_COPY_FIELD_DATA,
  FORM_SET_FIELD_STATE,
  FORM_SET_FIELD_FOCUS,
  FORM_FORCE_VALIDATE,
  FORM_SET_FIELD_STEP,
  FORM_SET_FIELD_TYPE,
  FORM_SET_VALIDATOR,
  FORM_SHOW_ERRORS,
  FORM_SET_VALUES,
  FORM_INIT_FIELD,
  FORM_VALIDATE,
  FORM_MERGE_FORMS,
  FORM_CLEAN,
  FORM_MERGE,
} from './consts';
import defaultFormState from './db';

// /////////////////////////////////////////////////////////
//
// Form reducers
//
// /////////////////////////////////////////////////////////

function clean(formState) {
  const validator = formState.get('validator');
  const defaultData = formState.get('defaultData');
  const defaultValues = formState.get('defaultValues');
  const types = formState.get('types');
  const data =
    defaultData && defaultData.map((v) => (List.isList(v) ? v.slice(0, 1) : v));
  const values =
    defaultValues &&
    defaultValues.map((v) => (List.isList(v) ? v.slice(0, 1) : v));

  return defaultFormState
    .set('data', data)
    .set('types', types)
    .set('values', values)
    .set('defaultData', data)
    .set('validator', validator)
    .set('defaultValues', values);
}

function validate(formState) {
  const validator = formState.get('validator');
  const data = formState.get('data') || Map();
  const values = formState.get('values') || Map();
  const errors = validator(data, values);
  return formState.set('errors', errors);
}

function setValues(formState, { values }) {
  return formState.set('values', values);
}

function convertValues(formState, values) {
  const data = {};
  const keys = values.keySeq();
  for (let i = 0; i < keys.size; i += 1) {
    const k = keys.get(i);
    const v = values.get(k);
    const type = formState.getIn(['types', k]);
    data[k] = stringToData(type, v);
  }
  return Map(data);
}

function merge(formState, { values }) {
  const data = convertValues(formState, values);
  const newFormState = formState
    .update('data', (m) => m.merge(data))
    .update('values', (m) => m.merge(values));
  return validate(newFormState);
}

function setRemoteValidations(formState, { validations }) {
  return formState.set('remoteValidations', validations);
}

function setValidator(formState, { validator }) {
  const currentData = either(formState.get('data'), Map());
  const currentValues = either(formState.get('values'), Map());
  const errors = validator ? validator(currentData, currentValues) : null;
  return formState.set('errors', errors).set('validator', validator);
}

function setSubmitState(formState) {
  return formState.set('showErrors', true).remove('remoteValidations');
}

function showErrors(formState, { show }) {
  return formState.set('showErrors', show);
}

function forceValidate(formState) {
  return setSubmitState(validate(formState));
}

// /////////////////////////////////////////////////////////
//
// Field reducers
//
// /////////////////////////////////////////////////////////

function initSimpleField(formState, { fieldType, field }, dvalue, ddata) {
  const value = formState.getIn(['values', field]);
  const data = stringToData(fieldType, value);

  return formState.withMutations((m) =>
    m
      .setIn(['types', field], fieldType)
      .setIn(['defaultData', field], ddata)
      .setIn(['defaultValues', field], dvalue)
      .setIn(['data', field], either(data, ddata))
      .setIn(['values', field], either(value, dvalue)),
  );
}

function initMultiField(formState, action, dvalue, ddata) {
  const { fieldType, multifield, index, field } = action;
  const value = formState.getIn(['values', multifield, index, field]);
  const data = stringToData(fieldType, value);
  const createUpdater = (value) => (state = List()) => {
    const m = state.get(index);
    if (m) {
      return state.setIn([index, field], value);
    }
    return state.set(index, Map({ [field]: value }));
  };

  const v = either(value, dvalue);
  return formState
    .updateIn(['data', multifield], List(), createUpdater(either(data, ddata)))
    .updateIn(['defaultValues', multifield], List(), createUpdater(dvalue))
    .updateIn(['defaultData', multifield], List(), createUpdater(ddata))
    .updateIn(['types', multifield], List(), createUpdater(fieldType))
    .updateIn(['values', multifield], List(), createUpdater(v));
}

function initField(formState, action) {
  const { index, fieldType, defaultValue } = action;

  const defaultData = onSome(defaultValue, (x) => stringToData(fieldType, x));
  const currentData = either(formState.get('data'), Map());
  const currentValues = either(formState.get('values'), Map());
  const validator = formState.get('validator');
  const errors = validator ? validator(currentData, currentValues) : null;
  const newState = formState.set('errors', errors);
  if (!some(index)) {
    return initSimpleField(newState, action, defaultValue, defaultData);
  }
  return initMultiField(newState, action, defaultValue, defaultData);
}

function setSimpleFieldState(formState, field, value) {
  const type = formState.getIn(['types', field]);
  const data = stringToData(type, value);

  const newState = formState
    .setIn(['data', field], data)
    .setIn(['values', field], value)
    .deleteIn(['remoteValidations', field]);

  // Atualiza o tamanho caso seja um multifield
  if (List.isList(data) && some(newState.getIn(['multifieldCount', field]))) {
    return newState.setIn(['multifieldCount', field], data.size);
  }
  return newState;
}

function setMultiFieldState(formState, { multifield, index, field, value }) {
  const type = formState.getIn(['types', multifield, index, field]);
  const data = stringToData(type, value);
  // const vfn = (values) => values.setIn([index, field], value);
  // const dfn = (values) => values.setIn([index, field], data);
  // TODO as atrocidades a seguir são fruto de alguém que já está com
  //      raiva.
  const vfn = (values = List()) =>
    values.update(index, (m = Map()) => m.set(field, value));
  const dfn = (values = List()) =>
    values.update(index, (m = Map()) => m.set(field, data));
  const delfn = (validations = List()) =>
    validations.update(index, (m = Map()) => {
      if (m && m.get(field)) {
        return m.delete(field);
      }
      return m;
    });

  return formState
    .updateIn(['data', multifield], List(), dfn)
    .updateIn(['values', multifield], List(), vfn)
    .updateIn(['remoteValidations', multifield], List(), delfn);
}

function setFieldState(formState, action) {
  const { multifield, index, field, value } = action;
  if (!some(index)) {
    return setSimpleFieldState(formState, field, value);
  }
  return setMultiFieldState(formState, { multifield, index, field, value });
}

function setType(formState, action) {
  const { multifield, index, field, fieldType } = action;
  if (!some(index)) {
    return formState.setIn(['types', field], fieldType);
  }
  return formState.setIn(['types', multifield, index, field], fieldType);
}

function intoKeyPath(key) {
  if (!Array.isArray(key)) {
    return [key];
  }
  return key;
}

function copyFieldData(formState, { from, to }) {
  const fk = intoKeyPath(from);
  const tk = intoKeyPath(to);
  const data = formState.getIn(['data', ...fk]);
  const value = formState.getIn(['values', ...fk]);
  return formState.setIn(['data', ...tk], data).setIn(['values', ...tk], value);
}

function formErrors(formState, field, index, multifield, disabled) {
  const validator = formState.get('validator');
  const data = formState.get('data');
  const values = formState.get('values');
  const errors = validator(either(data, Map()), either(values, Map()));

  if (disabled) {
    if (!some(index)) {
      return errors.remove(field);
    }
    return errors.removeIn([multifield, index, field]);
  }
  return errors;
}

function setFieldFocusAndValidate(formState, action) {
  const { field, isFocused, index, multifield, disabled } = action;
  const focus = isFocused ? 'on' : 'off';
  const errors = formErrors(formState, field, index, multifield, disabled);
  const newFormState = formState.set('errors', errors);
  if (!some(index)) {
    return newFormState.setIn(['focus', field], focus);
  }

  const ffn = (values) =>
    values.update(index, (m) => {
      if (!m) {
        return Map({ [field]: focus });
      }
      return m.set(field, focus);
    });
  return newFormState.updateIn(['focus', multifield], List(), ffn);
}

function removeMultifieldRow(formState, { multifield, index }) {
  const newFormState = formState
    .removeIn(['data', multifield, index])
    .removeIn(['focus', multifield, index])
    .removeIn(['values', multifield, index])
    .update('errors', (errors) =>
      errors ? errors.removeIn([multifield, index]) : Map(),
    )
    .removeIn(['remoteValidations', multifield, index])
    .updateIn(['multifieldCount', multifield], 1, (c) =>
      c && c > 1 ? c - 1 : 1,
    );

  return validate(newFormState);
}

function addMultifieldRow(formState, { multifield, index }) {
  const values = formState.getIn(['values', multifield]);
  const valuesCount = some(values) ? values.size : 2;
  const ufn = (size) => {
    if (!some(size)) {
      if (!formState.getIn(['data', multifield])) {
        return 2;
      }
      return formState.getIn(['data', multifield]).size + 1;
    }
    if (size && size >= valuesCount) {
      return size + 1;
    }
    return valuesCount + 1;
  };

  const insertNewIndex = (list) => {
    if (!list) {
      return List([Map()]);
    }
    // TODO Isso é uma gambiarra do cacete. Certamente os redutores
    //      foram mudandos sem levar em conta o comportamento em
    //      produção.
    if (Map.isMap(list)) {
      let newList = List();
      // TODO Isso é um péssimo exemplo de uso immutable.js. Se você
      //      está usando forEach, releia a documentação.
      list.forEach((v) => {
        newList = newList.push(v);
      });

      return newList.insert(index, Map());
    }
    return list.insert(index, Map());
  };

  let newFormState = formState
    .updateIn(['multifieldCount', multifield], ufn)
    .updateIn(['data', multifield], insertNewIndex)
    .update('errors', (errors) =>
      errors ? errors.removeIn([multifield, index]) : Map(),
    );

  if (newFormState.getIn(['focus', multifield])) {
    newFormState = newFormState.updateIn(['focus', multifield], insertNewIndex);
  }

  if (newFormState.getIn(['values', multifield])) {
    newFormState = newFormState.updateIn(
      ['values', multifield],
      insertNewIndex,
    );
  }
  if (newFormState.getIn(['remoteValidations', multifield])) {
    newFormState = newFormState.updateIn(
      ['remoteValidations', multifield],
      insertNewIndex,
    );
  }
  return newFormState;
}

function mergeForms(state = Map(), action) {
  const { formA, formB, view } = action;
  const formBState = state.getIn(['views', view, 'forms', formB]);
  return state.updateIn(['views', view, 'forms', formA], (formAState) => {
    const s = formAState || defaultFormState;
    return s
      .mergeIn(['data'], formBState.get('data'))
      .mergeIn(['values'], formBState.get('values'));
  });
}

function setFieldStep(formState, { field, step }) {
  return formState.updateIn(['fieldsSteps', step], (set) => {
    if (set) {
      return set.add(field);
    }
    return Set([field]);
  });
}

// /////////////////////////////////////////////////////////
//
// Reducer
//
// /////////////////////////////////////////////////////////

const updaters = {
  [FORM_SET_REMOTE_VALIDATIONS]: setRemoteValidations,
  [FORM_REMOVE_MULTIFIELD_ROW]: removeMultifieldRow,
  [FORM_SET_FIELD_FOCUS]: setFieldFocusAndValidate,
  [FORM_ADD_MULTIFIELD_ROW]: addMultifieldRow,
  [FORM_SET_SUBMIT_STATE]: setSubmitState,
  [FORM_SET_FIELD_STATE]: setFieldState,
  [FORM_SET_FIELD_STEP]: setFieldStep,
  [FORM_COPY_FIELD_DATA]: copyFieldData,
  [FORM_FORCE_VALIDATE]: forceValidate,
  [FORM_SET_VALIDATOR]: setValidator,
  [FORM_SHOW_ERRORS]: showErrors,
  [FORM_SET_FIELD_TYPE]: setType,
  [FORM_INIT_FIELD]: initField,
  [FORM_SET_VALUES]: setValues,
  [FORM_VALIDATE]: validate,
  [FORM_CLEAN]: clean,
  [FORM_MERGE]: merge,
};

function reducer(state, action) {
  if (action.type === FORM_MERGE_FORMS) {
    return mergeForms(state, action);
  }
  const updater = updaters[action.type];
  return state.updateIn(
    ['views', action.view, 'forms', action.form],
    defaultFormState,
    (formState) => updater(formState, action),
  );
}

export default reducer;
