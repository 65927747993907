/**
 * @module core/session/states
 */

/**
 * `Enum` de estados da conexão:
 * * `ALIVE`: estado da sessão logo após o login.
 * * `ENDED`: estado da sessão quando o token expira.
 * * `SUSPENDED`: estado da sessão quando se muda de organização.
 */
const SessionStates = {
  ALIVE: 'ALIVE',
  ENDED: 'ENDED',
  SUSPENDED: 'SUSPENDED',
};

export default SessionStates;
