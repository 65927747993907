/**
 * @module core/session/queries
 */
import SessionState from "./session-states";

/**
 * Retorna o `token` de sessão salvo no Redux.
 *
 * O `token` salvo no `cookie` só é relevante para o servidor
 * de front-end (esquilo).
 *
 * @param {Map} state o estado do redux.
 * @returns {String} o `token` de sessão.
 */
function getToken(state) {
  return state.getIn(["session", "token"]);
}

/**
 * Retorna os dados de sessão e perfil do usuário recebidos
 * da API no momento em que o usuário abre a aplicação.
 *
 * @param {Map} state o estado do redux.
 * @returns {Map} os dados de sessão e perfil do usuário.
 */
function getUserProfile(state) {
  return state.getIn(["session", "profile"]);
}

/**
 * Retorna o código (chave primária) da organização que o usuário
 * está atualmente acessando.
 *
 * Quando faz-se uso do View.createViewRenderer, esse valor virá
 * no objeto `props` com o nome `currentOrganizationID`.
 *
 * @param {Map} state o estado do redux.
 * @returns {Number} o código (chave primária) da organização.
 */
function getOrganizationID(state) {
  return state.getIn(["session", "profile", "orgUltAcesso", "codigo"]);
}

/**
 * Retorna as configurações do sistema enviadas pela API. Nesta
 * configuração estão parâmetros como: quantos caracteres o
 * usuário deve digitar e quanto tempo esperar depois do último
 * caractere digitado para que se faça uma requisição à API.
 *
 * No geral essa função só utilizada indiretamente através da
 * ação `comm.getFiltered`.
 *
 * @param {Map} state o estado do redux.
 * @returns {Map} as configurações do sistema.
 */
function getSystemParams(state) {
  return state.getIn(["session", "profile", "parametros"]);
}

/**
 * Retorna **true** se o usuário logado é um formando ou
 * formando comissão.
 *
 * @param {Map} state
 */
function isStudent(state) {
  const user = getUserProfile(state);
  const profileType = user.getIn(["perfil", "tipoUsuario"]);
  return profileType === "F";
}

/**
 * Retorna **true** se o usuário logado é um formando.
 *
 * @param {Map} state
 */
function isRegularStudent(state) {
  const user = getUserProfile(state);
  return user.getIn(["perfil", "codigo"]) === 4;
}

/**
 * Retorna **true** se o usuário logado é um formando desistente.
 *
 * @param {Map} state
 */
function isDropoutStudent(state) {
  const user = getUserProfile(state);
  return user.getIn(["perfil", "codigo"]) === 10;
}

/**
 * Retorna **true** se o usuário logado é um formando
 * comissão.
 *
 * @param {Map} state
 */
function isCommitteeStudent(state) {
  const user = getUserProfile(state);
  return user.getIn(["perfil", "codigo"]) === 5;
}

/**
 * Retorna **true** se o usuário logado é um administrador.
 *
 * @param {Map} state
 */

function isAdm(state) {
  const profileCod = state.getIn(["session", "profile", "perfil", "codigo"]);
  return profileCod === 1;
}

/**
 * Retorna **true** se o usuário logado é um bank.
 *
 * @param {Map} state
 */

function isBank(state) {
  const profileCod = state.getIn(["session", "profile", "perfil", "codigo"]);
  return profileCod === 11;
}

/**
 * Retorna **true** se o usuário é um formando da comissão de uma
 * formatura que não é administrada por um cerimonial.
 *
 * @deprecated
 * @param {Map} state
 */
function isCommitteeManager(state) {
  const user = getUserProfile(state);
  const isCommittee = user.getIn(["perfil", "codigo"]) === 5;
  if (!isCommittee) {
    return false;
  }
  const cerimonial = user.getIn(["orgUltAcesso", "cerimonial"]);
  return !cerimonial || cerimonial.get("codigo") === 1;
}

/**
 * Retorna se o usuário logado está com o perfil cerimonial ou
 * cermonial-master.
 *
 * @param {Map} state
 */
function isEnterprise(state) {
  const profile = state.getIn(["session", "profile", "perfil", "codigo"]);
  return profile === 2 || profile === 3;
}

/**
 * Retorna `true` se a organização for do tipo formatura
 * autoadministrada.
 *
 * @param {Map} state
 */
function isSelfManaged(state) {
  const manager = state.getIn(["session", "profile", "orgUltAcesso", "indAutoAdm"]);
  return isStudent(state) && manager;
}

/**
 * Retorna se o usuário logado é do tipo cermonial ou
 * um formando da comissão não administrado.
 *
 * @param {Map} state
 */
function isManager(state) {
  const userData = getUserProfile(state);

  if (!userData) {
    return false;
  }

  const codProfile = userData.getIn(["perfil", "codigo"]);
  const autoAdm = Boolean(userData.getIn(["orgUltAcesso", "indAutoAdm"]));

  return codProfile === 2 || codProfile === 3 || (codProfile === 5 && autoAdm);
}

/**
 * Returna o estado da conexão.
 *
 * @param {*} state
 * @returns {SessionStates} o estado da conexão.
 */
function getSessionState(state) {
  return state.getIn(["session", "state"]) || SessionState.ALIVE;
}

const queries = {
  isAdm,
  isBank,
  getToken,
  isManager,
  isStudent,
  isEnterprise,
  isSelfManaged,
  getUserProfile,
  getSystemParams,
  getSessionState,
  isDropoutStudent,
  isRegularStudent,
  getOrganizationID,
  isCommitteeManager,
  isCommitteeStudent,
};

export default queries;
