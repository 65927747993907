const applet = '/';

const view = `${applet}:code`;

const urls = {
  get: {
    address: '/v1/pub/enderecos/:cep',
    configCrowdfunding: '/v1/pub/vaquinhas/:code/config',
  },
  post: { donation: '/v1/pub/vaquinhas/:code/doacoes' },
};

export { view, applet, urls };
