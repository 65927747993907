import { Map } from 'immutable';
import { PIPE_WRITE, PIPE_ACQUIRE } from './consts';

function write(state, key, value) {
  return state.setIn(['pipe', 'writing', key], value);
}

function acquire(state) {
  const pipeState = state.get('pipe');
  const currentView = state.get('currentView');
  const ownerView = pipeState.get('ownerView');
  if (currentView === ownerView) {
    return state;
  }

  const written = pipeState.get('writing') || Map();
  return state.set(
    'pipe',
    pipeState
      .set('writing', Map())
      .set('reading', written)
      .set('ownerView', currentView),
  );
}

function reducer(state, action) {
  const { type, key, value } = action;
  if (type === PIPE_WRITE) {
    return write(state, key, value);
  }
  if (type === PIPE_ACQUIRE) {
    return acquire(state);
  }
  return state;
}

export default reducer;
