/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */

import { ENV } from '../config';

const isDev = ENV === 'development';

const Console = {
  error(message) {
    isDev && console.error(message);
  },
  warn(message) {
    isDev && console.warn(message);
  },
  log(message) {
    isDev && console.log(message);
  },
};

export default Console;
