/**
 * @module core/reducer
 */
import { List } from 'immutable';
import { NAMESPACE as CACHE_NAMESPACE } from './cache/consts';
import cache from './cache/reducer';
import { NAMESPACE as COMM_NAMESPACE } from './comm/consts';
import comm from './comm/reducer';
import components from './components/reducer';
import { MOUNT_VIEW, UNMOUNT_VIEW } from './consts';
import { defaultDB, defaultViewState } from './db';
import { NAMESPACE as REPORT_NAMESPACE } from './report/consts';
import report from './report/reducer';
import { NAMESPACE as SESSION_NAMESPACE } from './session/consts';
import session from './session/reducer';
import { NAMESPACE as STORAGE_NAMESPACE } from './storage/consts';
import storage from './storage/reducer';
import { NAMESPACE as UTIL_NAMESPACE } from './util/consts';
import utilReducer from './util/reducer';
import { NAMESPACE as PIPE_NAMESPACE } from './pipe/consts';
import pipeReducer from './pipe/reducer';

// Caso o a `view` sendo montada pertença a um `applet` diferente da `view`
// anterior, os dados do `applet` da `view` anterior  serão removidos do
// Redux.
//
// @param {Map} state estado do Redux.
// @param {string} applet `id` do `applet` da `view` sendo montada.
function unmountAppletIfNeeded(state, applet) {
  let currentApplet = state.get('currentApplet', List());
  const recentApplet = currentApplet.get(-1);
  if (recentApplet === applet) {
    return state;
  }

  const isOld = currentApplet.includes(applet);
  let appletsToRemove = List();
  while (currentApplet.size > 0) {
    const a = currentApplet.get(-1);
    if (a !== '/' && applet.startsWith(a)) {
      break;
    }
    appletsToRemove = appletsToRemove.push(a);
    currentApplet = currentApplet.pop();
  }

  let newState = state;
  appletsToRemove.forEach((el) => {
    newState = newState.deleteIn(['views', el]);
  });
  if (isOld) {
    return newState.set('currentApplet', currentApplet);
  }

  return newState
    .updateIn(['views', applet], (x) => defaultViewState.merge(x))
    .set(
      'currentApplet',
      currentApplet.size === 0 ? List([applet]) : currentApplet.push(applet),
    );
}

/**
 * Monta uma `view` num determinado `applet`. Caso já exista algum dado dentro
 * da `view`, geralemente dados configurados a partir de uma `view` anterior,
 * ele será mesclado com o `defaultViewState`.
 *
 * Se o `applet` desta `view` for diferente do `applet` da `view` anterior
 * (salvo no `defaultDB` como `currentApplet`) o `applet` anterior será des-
 * montado e novo montado.
 *
 * A montagem de um `applet` é semelhante à montagem de uma `view`. De fato,
 * assim como os de uma `view`, os dados de um applet são salvos no chave
 * `views` do `defaultaDB`, logo **os dados de um `applet` podem colidir com
 * os dados de uma `view`**, porém há o benefício de se ter uma API comum de
 * acesso aos dados salvos em `applets` e `views`.
 *
 * @param {Map} state estado do Redux.
 * @param {Object} action ação criada pelo action creator correspondente.
 * @returns {Map} o estado do Redux com o applet atualizado.
 */
function mountView(state, action) {
  const { view, applet } = action;
  const newState = unmountAppletIfNeeded(state, applet);

  return newState
    .updateIn(['views', view], (x) => defaultViewState.merge(x))
    .set('currentView', view);
}

/**
 * Desmonta uma `view` num determinado `applet`, ou seja, remove do Redux
 * todos valores (e chaves) armazenados dentro da `view`
 *
 * @param {Map} state estado do Redux.
 * @param {Object} action ação criada pelo action creator correspondente.
 * @returns {Map} o estado do Redux com os dados da `view` removidos.
 */
function unmountView(state, action) {
  return state.deleteIn(['views', action.view]);
}

/**
 * Redutor de topo de nível pelo todas ações dispachadas para o Redux passarão.
 *
 * @param {Map} state estado do Redux.
 * @param {Object} action uma ação criada por qualquer `action creator`.
 * @returns {Map} o estado do Redux após a aplicação das ações.
 */
function reducer(state = defaultDB, action) {
  const { type } = action;

  if (type === MOUNT_VIEW) {
    return mountView(state, action);
  }
  if (type === UNMOUNT_VIEW) {
    return unmountView(state, action);
  }

  switch (action.namespace) {
    case CACHE_NAMESPACE:
      // o subsistema «cache» é responsável por armazenar dados
      // vindos da API em `views` e `applets`.
      return cache(state, action);
    case COMM_NAMESPACE:
      // o subsistema «comm» é responsável pela comunicação com
      // a API, armazenar o status da comunicação e por dividir
      // os dados recebidos e salvar as partes entre os subsistemas.
      return comm(state, action);
    case REPORT_NAMESPACE:
      // o subsistema «report» é responsável por armazenar mensagens
      // criadas pelas `views` ou enviadas pela API.
      return report(state, action);
    case SESSION_NAMESPACE:
      // o subsistema sessions é responsável por armazenar dados
      // de sessão do usuário como perfil e configuração.
      return session(state, action);
    case STORAGE_NAMESPACE:
      // o sistema storage é um sistema de armazenamento genérico
      // que permite que qualquer componente ou `view` tenha acesso
      // ao redux store sem a necessidade de criar ações e reducers
      // próprios.
      return storage(state, action);
    case PIPE_NAMESPACE:
      return pipeReducer(state, action);
    case UTIL_NAMESPACE:
      return utilReducer(state, action);
    default:
      // contém reducers personalizados para os componentes específicos
      // como Table, Form e FilterForm.
      return components(state, action);
  }
}

export default reducer;
