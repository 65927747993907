import { List } from 'immutable';
import {
  TABBED_NEXT,
  TABBED_RESET,
  TABBED_SET_TAB,
  TABBED_PREVIOUS,
  TABBED_SET_TAB_COUNT,
  TABBED_SET_TAB_STATUS,
  TABBED_SET_CURRENT_TAB_STATUS,
} from './consts';
import defaultState from './db';

function setTab(tabbedState, { tab }) {
  return tabbedState.set('currentTab', tab);
}

function previous(tabbedState) {
  return tabbedState.update('currentTab', 0, (tab) => {
    if (tab > 1) {
      return tab - 1;
    }
    return 0;
  });
}

function next(tabbedState) {
  return tabbedState.update('currentTab', 0, (tab) => {
    if (tab) {
      return tab + 1;
    }
    return 1;
  });
}

function setStatus(tabbedState, { tab, status }) {
  return tabbedState.update('status', List(), (ss) => ss.set(tab, status));
}

function setCurrentTabStatus(tabbedState, { status }) {
  const current = tabbedState.get('currentTab') || 0;
  return tabbedState.setIn(['status', current], status);
}

function setTabCount(tabbedState, { count }) {
  return tabbedState.set('tabCount', count);
}

function reset() {
  return defaultState;
}

// /////////////////////////////////////////////////////////
//
// Reducer
//
// /////////////////////////////////////////////////////////

const updaters = {
  [TABBED_NEXT]: next,
  [TABBED_RESET]: reset,
  [TABBED_SET_TAB]: setTab,
  [TABBED_PREVIOUS]: previous,
  [TABBED_SET_TAB_STATUS]: setStatus,
  [TABBED_SET_TAB_COUNT]: setTabCount,
  [TABBED_SET_CURRENT_TAB_STATUS]: setCurrentTabStatus,
};

function reducer(state, action) {
  const updater = updaters[action.type];
  return state.updateIn(
    ['views', action.view, 'tabbed', action.tabbed],
    defaultState,
    (tabbedState) => updater(tabbedState, action),
  );
}

export default reducer;
