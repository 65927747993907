import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoot from '../../templates/AppRoot';
import Erro404 from '../../applets/error/Page404';
import Doacao from '../../views/vaquinha/doacao';

function Public() {
  return (
    <AppRoot isPublic>
      <Switch>
        {Doacao}
        {Erro404}
      </Switch>
    </AppRoot>
  );
}

export default Public;
