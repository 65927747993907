/**
 * @module core/config
 */

/**
 * Configura a URL da API (quokka). No ambiente de desenvolvimento o
 * valor dessa variável vem do arquivo '.env.dev'. Para os ambientes
 * de homologação, pré-produção e produção o valor será uma 'string'
 * curinga que será substituído pelo 'script' de implatação (veja o
 * arquivo .env.prd).
 */
const API_URL = `${process.env.API_URL}`;

/**
 * Configura a URL do servidor de 'front-end' (esquilo). Aplicam-se
 * aqui as mesmas regras de configuração que API_URL.
 */
const HOST_URL = `${process.env.ESQUILO_URL}`;

const TREX_URL = `${process.env.TREX_URL}`;

const ENV = `${process.env.NODE_ENV}`;

const PARTICIPE_URL = `${process.env.PARTICIPE_URL}`;

const VAQUINHA_URL = `${process.env.VAQUINHA_URL}`;

export { API_URL, HOST_URL, TREX_URL, ENV, PARTICIPE_URL, VAQUINHA_URL };
