/**
 * Este módulo contém as ações que fazem as primeiras requisições.
 * Obrigatoriamente elas devem ser as primeiras requisições feitas
 * à API, pois buscam as configurações, dados de perfil e o `token`
 * de sessão.
 *
 * @module core/session/handshake/actions
 */
import { List, Map } from 'immutable';
import cookies from 'js-cookie';

import { Header, Request, Response } from '../../comm';
import comm from '../../comm/actions';
import sessions from '../actions';

// import { views as contractViews } from '../../../applets/contratos/core';

const SESSION_VIEW = '/suaformatura/coruja/core/session/init/view';

// TODO remover função
// DEPRECATED esta função não faz mais sentido, pois o coruja
//            não é mais executado dentro do T-Rex em modo
//            headless.
function getToken() {
  // caso o coruja esteja sendo executado em mod
  // headless, o token será pego do campo de input
  // (hidden) cuja ID é 'zgTrexEsquiloID'.
  const tokenNode = document.getElementById('zgTrexEsquiloID');
  if (tokenNode && tokenNode.value) {
    return tokenNode.value;
  }
  return cookies.get('token');
}

/**
 * TODO: Mostrar uma tela de falhar no login quando essa rota falhar.
 *
 * Busca os dados de sessão, perfil do usuário e configuração
 * do sistema e salvo-os no Redux.
 *
 * [Thunk*](https://github.com/reduxjs/redux-thunk).
 *
 * @returns {Function} um nova ação assíncrona (thunk*).
 */
function init(pushURL = null, force = false) {
  const token = getToken();
  return comm.get(
    Request({
      view: SESSION_VIEW,
      force,
      url: '/v1/all/usuario_conf',
      header: Header({
        token,
        include: List(['perfil']),
      }),
      onSuccess: Response({
        dismiss: true,
        dispatch: List([
          sessions.setUserProfile,
          // (data) => {
          //   if (pushURL && data.get('indAssinarContrato')) {
          //     pushURL(contractViews.signature);
          //   }
          // },
        ]),
        transform: (data) => data && data.first(),
      }),
    }),
  );
}

/**
 * Busca os dados de configuração pública do sistema e
 * salvo-os no Redux.
 *
 * [Thunk*](https://github.com/reduxjs/redux-thunk).
 *
 * @returns {Function} um nova ação assíncrona (thunk*).
 */
function initPublic() {
  return comm.get(
    Request({
      view: SESSION_VIEW,
      url: '/v1/pub/configs',
      onSuccess: Response({
        dismiss: true,
        dispatch: sessions.setUserProfile,
        transform: (data) => data && Map({ parametros: data.first() }),
      }),
    }),
  );
}

const actions = {
  initPublic,
  init,
};

export default actions;
