import {
  COMM_SAVE_REQUEST,
  COMM_SET_UPLOADING,
  COMM_SET_DOWNLOADING,
  COMM_SET_LAST_RESPONSE_SIZE,
} from './consts';

function setCommunicating(state, direction, view, resource, value) {
  if (value) {
    return state.setIn(['comm', direction, view, resource], true);
  }
  const newState = state.deleteIn(['comm', direction, view, resource]);
  const remainKP = ['comm', direction, view];
  const remain = newState.getIn(remainKP);
  return remain && remain.size > 0 ? newState : newState.deleteIn(remainKP);
}

function saveRequest(state, { method, request }) {
  const view = request.get('view');
  const resource = request.get('url');
  return state.setIn(['views', view, 'comm', method, resource], request);
}

function setLastResponseSize(state, { request, size }) {
  const view = request.get('view');
  const resource = request.get('request');
  return state.setIn(
    ['views', view, 'comm', 'lastResponseSize', resource],
    size,
  );
}

// /////////////////////////////////////////////////////////
//
// Reducer
//
// /////////////////////////////////////////////////////////

function reducer(state, action) {
  const { view, resource, type, value } = action;
  switch (type) {
    case COMM_SET_UPLOADING:
      return setCommunicating(state, 'isSending', view, resource, value);
    case COMM_SET_DOWNLOADING:
      return setCommunicating(state, 'isLoading', view, resource, value);
    case COMM_SAVE_REQUEST:
      return saveRequest(state, action);
    case COMM_SET_LAST_RESPONSE_SIZE:
      return setLastResponseSize(state, action);
    default:
      return state;
  }
}

export default reducer;
