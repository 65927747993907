function createRegexp(key) {
  const kw = `:${key}`;
  const r = ['(', kw, '|', '\\{\\s*', key, '\\s*\\})'];

  return new RegExp(r.join(''), 'gi');
}

function applyURLVars(url, vars) {
  if (!url || !vars) {
    return url;
  }

  let result = url;
  const varKeys = vars.keySeq();
  for (let i = 0; i < varKeys.size; i += 1) {
    const k = varKeys.get(i);
    const value = vars.get(k);
    const r = createRegexp(k);
    result = result.replace(r, value);
  }
  return result;
}

/**
 * ! Adapter enquato o back não der suporte a filtros aninhados.
 *
 * @param {object} filters
 */
function filterAdapter(filters) {
  return filters.map((f) => {
    const { field } = f;
    return { ...f, field: field.split('.')[0] };
  });
}

function headers(requestParams, token) {
  const params = requestParams ? requestParams.toJS() : {};
  const header = { 'X-Broker': 'CORUJA' };

  if (params.pagination) {
    header['X-Pagination'] = JSON.stringify(params.pagination);
  }

  if (params.filter) {
    header['X-Filter'] = JSON.stringify(filterAdapter(params.filter));
  }

  if (params.sort) {
    header['X-Sort'] = JSON.stringify(params.sort);
  }

  if (params.include) {
    header['X-Include'] = JSON.stringify(params.include);
  }

  if (params.param) {
    header['X-Param'] = JSON.stringify(params.param);
  }

  if (params.report) {
    header['X-Report'] = JSON.stringify(params.report);
  }

  if (params.disposition) {
    header['X-Disposition'] = JSON.stringify(params.disposition);
  }

  if (params.accept) {
    header.Accept = JSON.stringify(params.accept);
  }

  if (token) {
    header.Authorization = `Bearer ${token}`;
  }

  return header;
}

export { applyURLVars, headers };
