/**
 * @module core/util/queries
 */

/**
 * Retorna um temporizador salvo na `view` com o `id`. O valor retornado
 * é um `Map` com as chaves `id`, que é o valor retornado por `setTimeout`,
 * e `cancel`, que é a função `() => clearTimeout(id)`.
 *
 * @param {Map} state o estado do Redux.
 * @param {String} view o `id` da `view` onde o temporizador foi salvo.
 * @param {String} id o `id` do temporizador dado pelo usuário da função.
 * @returns {Map} um temporizador.
 */
function getTimeout(state, view, id) {
  return state.getIn(['views', view, 'util', 'timeout', id]);
}

const queries = { getTimeout };

export default queries;
