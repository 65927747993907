import React from 'react';
import { Route } from 'react-router-dom';
import { View } from '../../../components';
import { applet, view } from './core';

const DonationView = React.lazy(() => import('./DonationView'));

export default (
  <Route key={view} exact path={view}>
    <View view={view} applet={applet} component={DonationView} />
  </Route>
);
