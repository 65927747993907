/* eslint-disable no-console */
import { List, Map, Set, fromJS } from 'immutable';

import cache from '../../cache/actions';
import { Message, MessageType } from '../../report';
import report from '../../report/actions';
import session from '../../session/actions';

// /////////////////////////////////////////////////////////
//
// Validation Fns
//
// /////////////////////////////////////////////////////////

function validationToMessage(k, m) {
  return Message({
    id: `/suaformatura/coruja/comm/zpc/validation/${k}`,
    type: MessageType.ERROR,
    message: m,
  });
}

/**
 * Converte a validação do back para aparecer no snackbar.
 * @param {List} validations
 * @param {string} gmessage
 */
function convertValidations(validations, gmessage) {
  if (!validations) {
    return validations;
  }

  let res = [];
  const hasValidations = !validations || validations.size > 0;
  if (gmessage && !hasValidations) {
    res = [gmessage];
  }
  const keys = validations.keySeq();
  for (let i = 0; i < keys.size; i += 1) {
    const k = keys.get(i);
    const m = validations.get(k);
    if (typeof m === 'string') {
      res.push(validationToMessage(k, m));
    } else {
      res.push(
        validationToMessage(
          k,
          m.find((x) => Boolean(x)),
        ),
      );
    }
  }

  return List(res);
}

/**
 * Separa em erros que irão para o snackbar e erros que serão
 * dispachados.
 * @param {ValidationRequest} requiredValidations
 * @param {Map} validations
 */
function splitValidation(requiredValidations, validations) {
  if (!validations) {
    return [null, null];
  }

  const fields = requiredValidations.get('fields');
  const dispatch = requiredValidations.get('dispatch');
  const bypass = requiredValidations.get('bypassDefault');
  if (!dispatch && !fields) {
    return [null, convertValidations(validations)];
  }
  if (!fields) {
    return [validations, null];
  }

  const required = Set(fields);
  const errors = {};
  const remainingValidations = {};
  const keys = validations.keySeq();
  for (let i = 0; i < keys.size; i += 1) {
    const k = keys.get(i);
    const m = validations.get(k);
    if (required.has(k)) {
      remainingValidations[k] = m;
    } else {
      errors[k] = m;
    }
  }

  const vs = Map(remainingValidations);

  const gmessage =
    vs.size < 1 || bypass
      ? null
      : Message({
          id: '/suaformatura/coruja/comm/zpc/validation/generic~error~message!',
          type: MessageType.WARNING,
          message: 'Verifique os campos em vermelho e corrija os erros.',
        });
  return [vs, convertValidations(Map(errors), gmessage)];
}

function attachValidations(actions, validations, params) {
  const requiredValidations = params.get('requiredValidations');
  const [vs, errors] = splitValidation(requiredValidations, validations);
  let validationDispatch = null;
  if (requiredValidations) {
    validationDispatch = requiredValidations.get('dispatch');
  }

  if (vs && vs.size > 0 && validationDispatch) {
    if (typeof validationDispatch === 'function') {
      actions.push(validationDispatch(vs));
    } else {
      for (let i = 0; i < validationDispatch.size; i += 1) {
        const d = validationDispatch.get(i);
        const a = d && d(vs);
        if (a) {
          actions.push(d(vs));
        }
      }
    }
  }
  if (errors && errors.size > 0) {
    actions.push(report.showMessages(errors));
  }
}

// /////////////////////////////////////////////////////////
//
// Data Fns
//
// /////////////////////////////////////////////////////////

function attachRawData(actions, rawData, params, pagination) {
  const dispatchRaw = params.get('dispatchRaw');

  if (dispatchRaw) {
    if (typeof dispatchRaw === 'function') {
      actions.push(dispatchRaw(rawData));
    } else if (dispatchRaw) {
      for (let i = 0; i < dispatchRaw.size; i += 1) {
        const d = dispatchRaw.get(i);
        if (d) {
          actions.push(d(rawData));
        }
      }
    }
  }
}

function attachData(actions, data, params, pagination) {
  const effect = params.get('effect');
  const hasData = Boolean(data);
  if (!hasData && !effect) {
    return;
  }

  const view = params.get('view');
  const resource = params.get('resource');
  const dismiss = params.get('dismiss');
  const dispatch = params.get('dispatch');
  const page = params.get('page');

  if (!dismiss && hasData) {
    actions.push(cache.set(view, resource, data, page, fromJS(pagination)));
  }
  if (!dispatch) {
    return;
  }

  let dispatchIndex = 0;
  try {
    if (typeof dispatch === 'function') {
      actions.push(dispatch(data));
      return;
    }
    if (dispatch) {
      for (let i = 0; i < dispatch.size; i++) {
        const d = dispatch.get(i);
        const a = d && d(data);
        dispatchIndex += 1;

        if (a) {
          actions.push(a);
        }
      }
    }
  } catch (e) {
    console.error(`ZPC v2 attachData: erro ao executar o dispatch ${dispatchIndex}.`);
    console.error(e);
  }
}

// /////////////////////////////////////////////////////////
//
// Messages & exception
//
// /////////////////////////////////////////////////////////

function attachMessages(actions, response) {
  const ex = response.exception;
  const exID = '/suaformatura/coruja/comm/zpc/exception/';
  let messages = null;

  if (response.messages || ex) {
    messages = [];
    if (response.messages && response.messages.length > 0) {
      messages = messages.concat(response.messages);
    }
    if (ex) {
      messages.push({
        id: exID,
        type: MessageType.ERROR,
        message: ex.message,
      });
    }
    actions.push(report.showMessages(fromJS(messages)));
  }
}

// /////////////////////////////////////////////////////////
//
// Multiplex
//
// /////////////////////////////////////////////////////////

function dispatchOnError(actions, params) {
  const dispatch = params.get('dispatch');
  if (!dispatch) {
    return;
  }

  if (typeof dispatch === 'function') {
    actions.push(dispatch());
    return;
  }
  for (let i = 0; i < dispatch.size; i += 1) {
    const d = dispatch.get(i);
    if (d) {
      actions.push(d());
    }
  }
}

function convertRawData(rawData, params) {
  if (rawData === null || rawData === undefined) {
    return null;
  }

  const transform = params.get('transform');
  const convert = params.get('convert');
  const data = !convert ? rawData : fromJS(rawData);
  return transform(data);
}

function multiplex(response, params, error) {
  const rawData = response.data;
  const { pagination } = response;
  const validations = fromJS(response.validation);
  const data = convertRawData(rawData, params);
  const actions = [];

  if (response.token) {
    actions.push(session.setToken(response.token));
  }
  if (error) {
    dispatchOnError(actions, params);
  }
  attachValidations(actions, validations, params);
  attachRawData(actions, rawData, params, pagination);
  attachData(actions, data, params, pagination);
  attachMessages(actions, response, params);
  return { actions, data, size: rawData ? rawData.length : 0 };
}

export default multiplex;
