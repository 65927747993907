/**
 * @module core/util/reducer
 */
import { UTIL_DELETE_TIMEOUT, UTIL_SAVE_TIMEOUT } from './consts';

/**
 * Redutor das ações do módulo `util`.
 *
 * @param {Map} state o estado do Redux.
 * @param {Object} action ação de `util` que modificará o estado.
 * @returns {Map} um novo estado resultante da aplicação no estado atual.
 */
export default function reducer(state, { type, timeout, view, id }) {
  switch (type) {
    case UTIL_SAVE_TIMEOUT:
      return state.setIn(['views', view, 'util', 'timeout', id], timeout);
    case UTIL_DELETE_TIMEOUT:
      return state.removeIn(['views', view, 'util', 'timeout', id]);
    default:
      return state;
  }
}
