import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Error } from '@material-ui/icons';
import Section from '../../components/Section';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div style={{ maxWidth: '600px', margin: '24px auto' }}>
          <Section>
            <div style={{ maxWidth: '600px', margin: '24px auto' }}>
              <Typography align="center">
                <Error color="error" style={{ fontSize: '50' }} />
              </Typography>
              <Typography variant="h6" color="primary" align="center">
                Ops! Alguma coisa deu errado...
              </Typography>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                align="center"
                style={{ marginTop: '24px' }}
              >
                Sentimos muito pelo inconveniente. Por favor, entre em contato
                com a equipe <b>SuaFormatura</b> para que possamos ajudar.
              </Typography>
            </div>
          </Section>
        </div>
      );
    }

    return children;
  }
}

export default React.memo(ErrorBoundary);
