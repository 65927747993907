import moment from 'moment';
import defaultState from './db';
import {
  DATEFILTER_SET_LAST_ONES_OPTION,
  DATEFILTER_SET_DATE,
  DATEFILTER_SET_TAB,
} from './consts';

function setLastOnesOption(state, { option }) {
  return state.set('lastOnesOption', option);
}

function setTab(state, { tab }) {
  return state.set('tab', tab);
}

function setDate(state, { date, endDate }) {
  return state
    .set('date', moment(date).startOf('day'))
    .set(
      'endDate',
      endDate ? moment(endDate).endOf('day') : moment(date).endOf('day'),
    );
}

const updaters = {
  [DATEFILTER_SET_LAST_ONES_OPTION]: setLastOnesOption,
  [DATEFILTER_SET_DATE]: setDate,
  [DATEFILTER_SET_TAB]: setTab,
};

function reducer(state, action) {
  const updater = updaters[action.type];
  return state.updateIn(
    ['views', action.view, action.id],
    defaultState,
    (state) => updater(state, action),
  );
}

export default reducer;
