const NAMESPACE = 'components.datefilter';
const DATEFILTER_SET_TAB = 'DATEFILTER_SET_TAB';
const DATEFILTER_SET_DATE = 'DATEFILTER_SET_DATE';
const DATEFILTER_SET_LAST_ONES_OPTION = 'DATEFILTER_SET_LAST_ONES_OPTION';

export {
  NAMESPACE,
  DATEFILTER_SET_TAB,
  DATEFILTER_SET_DATE,
  DATEFILTER_SET_LAST_ONES_OPTION,
};
