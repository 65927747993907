import { NAMESPACE as DATAFILTER_NAMESPACE } from './datefilter/consts';
import { NAMESPACE as DATATABLE_NAMESPACE } from './datatable/consts';
import { NAMESPACE as TABBED_NAMESPACE } from './tabbed/consts';
import { NAMESPACE as FORM_NAMESPACE } from './form/consts';
import form from './form/reducer';
import datefilter from './datefilter/reducer';
import datatable from './datatable/reducer';
import tabbed from './tabbed/reducer';

function reducer(state, action) {
  switch (action.namespace) {
    case DATATABLE_NAMESPACE:
      return datatable(state, action);
    case DATAFILTER_NAMESPACE:
      return datefilter(state, action);
    case FORM_NAMESPACE:
      return form(state, action);
    case TABBED_NAMESPACE:
      return tabbed(state, action);
    default:
      return state;
  }
}

export default reducer;
