import axios from 'axios';
import { List, Map } from 'immutable';
import FileDownload from 'js-file-download';

import { Filter, Pagination, Request } from '.';
import { COMBOS_VIEW } from '../cache/consts';
import qcache from '../cache/queries';
import { API_URL, HOST_URL } from '../config';
import { Message, MessageType } from '../report';
import report from '../report/actions';
import session from '../session/actions';
import qsession from '../session/queries';
// eslint-disable-next-line camelcase
import { ATROCITY_some, processCombo } from '../util';
import uactions from '../util/actions';
import Console from '../util/console';
import { applyURLVars, headers } from './api';
import {
  COMM_SAVE_REQUEST,
  COMM_SET_DOWNLOADING,
  COMM_SET_LAST_RESPONSE_SIZE,
  COMM_SET_UPLOADING,
  NAMESPACE,
} from './consts';
import qcomm from './queries';
import multiplex from './zpc';

const PAGE_SIZE = 40;

// /////////////////////////////////////////////////////////
//
// Warning flags
//
// /////////////////////////////////////////////////////////

let getComboWarningFlag = false;
let fixRequestWarningFlag = false;

// /////////////////////////////////////////////////////////
//
// Actions de recarregamento e redirecionamento
//
// /////////////////////////////////////////////////////////

function refresh(link = null) {
  return () => {
    window.location.replace(link || window.location.href);
  };
}

// /////////////////////////////////////////////////////////
//
// Actions de requisição
//
// /////////////////////////////////////////////////////////

/**
 * Marca uma rota como uma para qual se está enviando
 * dados (ou não, veja 'flag').
 *
 * @param {string} view id da view onde a requisição
 * (get, post ...) originou.
 * @param {string} resource URL do recurso da API.
 * @param {boolean} flag quando 'true' significa que
 * dados estão sendo enviados e uma resposta é aguar-
 * dada.
 */
function setUploading(view, resource, flag) {
  return {
    namespace: NAMESPACE,
    type: COMM_SET_UPLOADING,
    value: flag,
    resource,
    view,
  };
}

/**
 * O mesmo que 'setUploading', mas no sentido oposto.
 */
function setDownloading(view, resource, flag) {
  return {
    namespace: NAMESPACE,
    type: COMM_SET_DOWNLOADING,
    value: flag,
    resource,
    view,
  };
}

/**
 * Função utilizada por httpRequest para salvar a uma requisição
 * Request.
 *
 * @param {string} method get, post, put...
 * @param {Request} request a requisição
 */
function saveRequest(method, request) {
  return {
    namespace: NAMESPACE,
    type: COMM_SAVE_REQUEST,
    method,
    request,
  };
}

function setLastResponseSize(request, size) {
  return {
    namespace: NAMESPACE,
    type: COMM_SET_LAST_RESPONSE_SIZE,
    request,
    size,
  };
}

// /////////////////////////////////////////////////////////
//
// Request
//
// /////////////////////////////////////////////////////////

function regenerateActions(params, data) {
  if (!data || data.size < 1) {
    return null;
  }

  const dismiss = params.get('dismiss');
  const dispatch = params.get('dispatch');
  if (!dispatch || dismiss) {
    return null;
  }

  const actions = [];
  if (typeof dispatch === 'function') {
    actions.push(dispatch(data));
  } else if (dispatch) {
    for (let i = 0; i < dispatch.size; i += 1) {
      const d = dispatch.get(i);
      if (d) {
        actions.push(d(data));
      }
    }
  }
  return actions;
}

function redispatch(request, state, dispatch) {
  const params = request.get('onSuccess');
  const view = request.get('view');
  const resource = request.get('url');
  const data = qcache.getData(state, view, resource);
  const actions = regenerateActions(params, data);
  if (!actions || actions.length < 1) {
    return;
  }

  for (let i = 0; i < actions.length; i += 1) {
    dispatch(actions[i]);
  }
}

function shouldSendRequest(state, method, request, oldRequest) {
  if (method !== 'get' || request.get('force')) {
    return true;
  }

  const view = request.get('view');
  const resource = request.get('url');
  const isValid = qcache.isValid(state, view, resource);
  return !isValid || !request.equals(oldRequest);
}

function responseHandler(response, dispatch, setCommunicating, onResponse, setResponseSize) {
  try {
    setCommunicating(false);
    const m = multiplex(response, onResponse);
    if (!m) {
      return null;
    }

    const { actions, data, size } = m;
    if (setResponseSize) {
      setResponseSize(size);
    }
    for (let i = 0; i < actions.length; i += 1) {
      const a = actions[i];
      if (ATROCITY_some(a)) {
        dispatch(a);
      }
    }
    return data;
  } catch (error) {
    Console.error(error);
    return null;
  }
}

function addPagination(request, oldRequest, lastResponseSize, page) {
  if (lastResponseSize === 0 || !page) {
    return request;
  }

  const last = oldRequest.getIn(['header', 'pagination']);
  const offset = (last && last.get('offset')) || 1;
  return request.setIn(
    ['header', 'pagination'],
    Pagination({
      limit: PAGE_SIZE,
      offset: offset + 1,
    }),
  );
}

function httpRequest(method, req, page = false) {
  const data = req.get('data');
  const view = req.get('view');
  const resource = req.get('url');
  const urlp = req.get('urlParams');
  const url = urlp ? applyURLVars(resource, urlp) : resource;
  return (dispatch, getState) => {
    const state = getState();
    const oldRequest = qcomm.retrieveSavedRequest(state, method, req);
    const lastResponseSize = qcomm.getLastResponseSize(state, view, resource);
    const request = addPagination(req, oldRequest, page, lastResponseSize);
    // um get só é executado se o cache estiver inválido ou
    // os parâmetros tiverem mudado
    if (!shouldSendRequest(state, method, request, oldRequest)) {
      if (request.getIn(['onCacheHit', 'redispatch'])) {
        redispatch(request, state, dispatch);
      }
      return null;
    }

    const isSetMethod = method === 'post' || method === 'put';
    const header = request.get('header');
    const transform = request.get('transform');
    const token = header.get('token') || qsession.getToken(state);
    const onSuccess = request
      .get('onSuccess')
      .set('view', view)
      .set('page', page)
      .set('resource', resource)
      .set('dismiss', isSetMethod || request.getIn(['onSuccess', 'dismiss']));
    const onError = request
      .get('onError')
      .set('view', view)
      .set('resource', resource)
      .set('dismiss', isSetMethod || request.getIn(['onError', 'dismiss']));

    const setCommunicating = (flag) => {
      if (method === 'get') {
        return dispatch(setDownloading(view, resource, flag));
      }
      return dispatch(setUploading(view, resource, flag));
    };
    const setResponseSize =
      method !== 'get' ? null : (size) => dispatch(setLastResponseSize(request, size));

    dispatch(saveRequest(method, request));
    setCommunicating(true);

    return axios({
      url,
      method,
      baseURL: API_URL,
      data: transform(data),
      headers: headers(header, token),
      responseType: 'json',
    })
      .then((response) =>
        responseHandler(response, dispatch, setCommunicating, onSuccess, setResponseSize),
      )
      .catch((error) => {
        const loggedOut = (error.response || {}).status === 401;
        if (loggedOut) {
          Console.error('[coruja] sessão expirada: fazendo logout...');
          Console.error(`[coruja] token não encontrado, logout: "${HOST_URL}/logout"`);
          dispatch(session.end());
          window.location.replace(`${HOST_URL}/logout`);
          return null;
        }
        return responseHandler(
          error.response,
          dispatch,
          setCommunicating,
          onError,
          setResponseSize,
        );
      });
  };
}

function executeTrigger(onResponse, data, dispatch) {
  if (typeof onResponse === 'function') {
    const action = onResponse(data);
    if (action) {
      dispatch(action);
    }
  }
  if (List.isList(onResponse)) {
    for (let i = 0; i < onResponse.size; i++) {
      const d = onResponse.get(i);
      const action = d(data);
      if (action) {
        dispatch(action);
      }
    }
  }
}

/**
 * Baixa um arquivo pego do backend.
 *
 * @param {Request} req descritor da requisição ser feita.
 */
function download(request) {
  const view = request.get('view');
  const resource = request.get('url');
  const urlp = request.get('urlParams');
  const headers = request.get('headers');
  const filename = request.get('filename');
  const authorize = request.get('authorize');
  const download = request.get('download');
  const data = request.get('data');
  const method = request.get('method');
  const onError = request.getIn(['onError', 'dispatch']);
  const onSuccess = request.getIn(['onSuccess', 'dispatch']);
  const url = urlp ? applyURLVars(resource, urlp) : resource;

  return (dispatch, getState) => {
    const state = getState();
    const token = qsession.getToken(state);

    const setCommunicating = (flag) => dispatch(setDownloading(view, resource, flag));

    setCommunicating(true);

    return axios({
      url,
      method,
      data,
      baseURL: API_URL,
      responseType: 'blob',
      headers: !authorize
        ? headers
        : {
            ...headers,
            'X-Broker': 'CORUJA',
            Authorization: `Bearer ${token}`,
          },
    })
      .then((response) => {
        const mime = response.headers['content-type'];
        const disposition = response.headers['content-disposition'];
        const filenameDisposition =
          disposition &&
          (disposition.split(/filename="(.+)"/)[1] || disposition.split('filename=')[1]);

        if (download !== false) {
          FileDownload(
            response && response.data,
            filename || filenameDisposition || 'report',
            mime,
          );
          const messages = List([
            Message({
              type: MessageType.NOTICE,
              id: '/suaformatura/coruja/comm/zpc/notices/',
              message: 'Arquivo baixado com sucesso.',
            }),
          ]);
          dispatch(report.showMessages(messages));
        }

        executeTrigger(onSuccess, response && URL.createObjectURL(response.data), dispatch);

        if (response.status) {
          setCommunicating(false);
        }
      })
      .catch((err) => {
        setCommunicating(false);
        const { data: blob, status } = err.response;
        const defaultMessage = Message({
          type: MessageType.ERROR,
          id: '/suaformatura/coruja/comm/zpc/errors/',
          message: 'Houve um erro ao tentar baixar o arquivo',
        });
        if (status === 422) {
          const reader = new FileReader();
          let jsonMessage = '';
          // eslint-disable-next-line func-names
          reader.onload = function () {
            jsonMessage = JSON.parse(this.result);
            let validationMessage = null;
            if (jsonMessage.validation && jsonMessage.validation.default) {
              validationMessage = Message({
                type: MessageType.ERROR,
                id: '/suaformatura/coruja/comm/zpc/errors/',
                message: jsonMessage.validation.default,
              });
            } else {
              validationMessage = defaultMessage;
            }

            dispatch(report.showMessages(List([validationMessage])));
          };
          reader.readAsText(blob);
        } else {
          const messages = List([defaultMessage]);
          dispatch(report.showMessages(messages));
        }

        executeTrigger(onError, dispatch);
      });
  };
}

// /////////////////////////////////////////////////////////
//
// Actions de requisição
//
// /////////////////////////////////////////////////////////

/**
 * Função utilizada por getCombo para aplicar as variáveis
 * de URL a priori. Leia os comentários de getCombo para
 * compreender.
 */
function augmentComboRequest(request, defaultView) {
  const view = request.get('view') || defaultView;
  return request.set('view', view).setIn(['onSuccess', 'transform'], processCombo);
}

/**
 * fixRequest recebe os parâmetros das funções sobrecarredas post,
 * put, get e getCombo e retorna o um objeto do tipo
 * [record](https://immutable-js.github.io/immutable-js/docs/#/Record)
 * Request que descreve a requisição.
 */
function fixRequest(request, maybeURL, defaultView) {
  if (typeof request !== 'string') {
    return request;
  }
  if (!fixRequestWarningFlag) {
    fixRequestWarningFlag = true;
    console.warn(
      'comm.fixRequest será depreciada.' +
        ' Ao utilizar comm.{get,post,put,remove,get,getFiltered}' +
        ' passe o objeto de request completo.',
    );
  }
  if (maybeURL) {
    return Request({ view: request, url: maybeURL });
  }
  return Request({ url: request, view: defaultView });
}

/**
 * getCombo executa uma requisição à API utilizando o método HTTP
 * GET.
 *
 * getCombo recebe os mesmo parâmetros que post.
 *
 * As diferenças entre get e getCombo são:
 * 1. por padrão getCombo salvará a resposta da requisição na 'view'
 * falsa 'COMBOS_VIEW' -- a não ser que outra view seja informada (veja
 * os comentários da função 'post'). Logo, por ser uma view falsa que
 * nunca é montada nem desmontada, o valor será armazenado por toda a
 * sessão do usuário);
 * 2. 'onSuccess' getCombo sempre aplicará a função util/processCombo
 * sobre a resposta antes de salvá-la no cache;
 * 3. ao passo que 'get' salvará os dados no cache identificados pela
 * url da API contendo a variáveis de URL (os dados serão salvos sob
 * '/v1/cer/contas/:conta' ao invés de '/v1/cer/contas/20'), getCombo
 * fará o oposto (os dados serão salvos sob '/v1/cer/contas/20' ao
 * invés de '/v1/cer/contas/:conta');
 */
function getCombo(request, urlParams) {
  let req = fixRequest(request, null, COMBOS_VIEW);
  if (urlParams) {
    req = req.set('urlParams', Map.isMap(urlParams) ? urlParams : Map(urlParams));
  }
  if (!getComboWarningFlag) {
    getComboWarningFlag = true;
    console.warn('comm.getCombo será depreciada.' + ' Utilize comm.get com processCombo ao invés.');
  }
  return httpRequest('get', augmentComboRequest(req, COMBOS_VIEW));
}

function getTimeout(request) {
  return (dispatch, getState) => {
    const url = request.get('url');
    const view = request.get('view');
    const config = qsession.getSystemParams(getState());
    const typeaheadTimeout = config.get('typeaheadTimeout');
    const typeaheadMaxItems = config.get('typeaheadMaxItens');
    const limitPagination = request.getIn(['header', 'pagination', 'limit']);

    let req = request;
    if (!limitPagination) {
      req = req.setIn(['header', 'pagination'], Pagination({ limit: typeaheadMaxItems }));
    }
    return dispatch(
      uactions.dispatchUniqueLater(view, url, httpRequest('get', req), typeaheadTimeout),
    );
  };
}

/**
 * Executa uma requisição get filtrando por um campo. A requisição é
 * atrasada 'typeaheadTimeout', que é um parâmetro de configuração.
 * Além disso, a mensagem só será enviada para a API caso 'filterValue'
 * tenha no mínimo 'typeaheadMinLenght' (parâmtro de configuração)
 * caracteres.
 *
 * @param {Request} request descritor da requisição ser feita;
 * @param {string} filterField campo pelo qual os resultados serão filtrados;
 * @param {string} filterValue valor pelo qual os resultados serão filtrados.
 */
function getFiltered(request, filterField, filterValue) {
  return (dispatch, getState) => {
    if (!filterField || !filterValue) {
      return null;
    }

    const config = qsession.getSystemParams(getState());
    const typeaheadMinLenght = config.get('typeaheadMinLenght');
    if (filterValue.length < typeaheadMinLenght) {
      return null;
    }

    const url = request.get('url');
    const view = request.get('view');
    const typeaheadTimeout = config.get('typeaheadTimeout');
    const typeaheadMaxItems = config.get('typeaheadMaxItens');

    const pagination = Pagination({ limit: typeaheadMaxItems });
    const filter = Filter({
      field: filterField,
      value: filterValue,
      op: typeof filterValue === 'string' ? '%' : '=',
    });

    const oldTransform = request.getIn(['onSuccess', 'transform']);
    let newTransform = null;
    if (oldTransform) {
      newTransform = (data) => oldTransform(processCombo(data));
    } else {
      newTransform = processCombo;
    }

    const req = request
      .setIn(['header', 'pagination'], pagination)
      .setIn(['onSuccess', 'transform'], newTransform)
      .updateIn(['header', 'filter'], (f) => (f && f.push(filter)) || List([filter]));
    return dispatch(
      uactions.dispatchUniqueLater(view, url, httpRequest('get', req), typeaheadTimeout),
    );
  };
}

/**
 * get executa uma requisição à API utilizando o método HTTP
 * GET.
 *
 * get recebe os mesmo parâmetros que post.
 */
function get(request, maybeURL, config = {}) {
  const req = fixRequest(request, maybeURL);
  return httpRequest('get', req, Boolean(config.page));
}

/**
 * get executa uma requisição à API utilizando o método HTTP
 * GET.
 *
 * get recebe os mesmo parâmetros que post.
 */
function remove(request, maybeURL, config = {}) {
  const req = fixRequest(request, maybeURL);
  return httpRequest('delete', req, Boolean(config.page));
}

/**
 * put executa uma requisição à API utilizando o método HTTP
 * PUT.
 *
 * put recebe os mesmo parâmetros que post.
 */
function put(request, maybeURL) {
  const req = fixRequest(request, maybeURL);
  return httpRequest('put', req);
}

/**
 * post executa uma requisição à API utilizando o método HTTP
 * POST.
 *
 * Esta função tem três sobrecargas:
 *
 * 1. post(url)
 * @param {string} url o caminho do recurso da API.
 *
 * 2. post(view, url)
 * @param {string} view o 'id' da 'view' que está fazendo requisição.
 * @param {string} url o caminho do recurso da API.
 *
 * 3. post(request)
 * @param {Request} request um objeto do tipo
 * [record](https://immutable-js.github.io/immutable-js/docs/#/Record) Request
 * que descreve a requisição.
 */
function post(request, maybeURL) {
  const req = fixRequest(request, maybeURL);
  return httpRequest('post', req);
}

function patch(request, maybeURL) {
  const req = fixRequest(request, maybeURL);
  return httpRequest('patch', req);
}

/*
 * ---------------------------------------------------------
 *
 * Exportação
 *
 * ---------------------------------------------------------
 */

const actions = {
  setDownloading,
  setUploading,
  getFiltered,
  getTimeout,
  getCombo,
  download,
  refresh,
  post,
  get,
  put,
  patch,
  remove,
  PAGE_SIZE,
};

export default actions;
