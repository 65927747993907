import { Map } from 'immutable';

import { CACHE_DELETE_DATA, CACHE_INVALIDATE, CACHE_SET_DATA, CACHE_UPDATE } from './consts';

function appendPage(page) {
  return (data) => {
    if (!data) {
      return page;
    }
    return data.concat(page);
  };
}

function setData(state, view, resource, data, page, pagination) {
  if (!page) {
    return state
      .setIn(['views', view, 'cache', resource, 'data'], data)
      .setIn(['views', view, 'cache', resource, 'pagination'], pagination);
  }
  return state.updateIn(['views', view, 'cache', resource, 'data'], appendPage);
}

function updateData(state, view, resource, ufn) {
  return state.updateIn(['views', view, 'cache', resource, 'data'], ufn);
}

function reducer(state, action) {
  const { type, view, resources, resource, data, page, updateFn, pagination } = action;

  switch (type) {
    case CACHE_SET_DATA:
      return setData(state, view, resource, data, page, pagination).setIn(
        ['views', view, 'cache', resource, 'isValid'],
        true,
      );
    case CACHE_DELETE_DATA:
      return state.deleteIn(['views', view, 'cache', resource]);
    case CACHE_UPDATE:
      return updateData(state, view, resource, updateFn).setIn(
        ['views', view, 'cache', resource, 'isValid'],
        true,
      );
    case CACHE_INVALIDATE:
      return state.updateIn(['views', view, 'cache'], Map(), (cache) =>
        cache.withMutations((map) => {
          let m = map;
          for (let i = 0; i < resources.length; i += 1) {
            const r = resources[i];
            m = m.setIn([r, 'isValid'], false);
          }
          return m;
        }),
      );
    default:
      return state;
  }
}

export default reducer;
