import { List } from 'immutable';
import { Message, MessageType } from '../../report';
import report from '../../report/actions';
import session from '../../session/actions';
import { HOST_URL } from '../../config';
import multiplexV2 from './v2';

function isPublicRoute(params) {
  const resource = params.get('resource') || '';
  return resource.startsWith('/v1/pub/') || resource.startsWith('v1/pub/');
}

function errorMux() {
  return null;
}

function multiplex(response, params) {
  if (!response) {
    return null;
  }

  const r = response.data;
  if (!isPublicRoute(params) && (!r.token || r.token === '')) {
    // TODO
    //      e agora José?
    console.log('[coruja] token ausente.');
    // window.location.replace(HOST_URL + '/logout');
    const messages = List([
      Message({
        type: MessageType.ERROR,
        id: '/suaformatura/coruja/comm/zpc/validation/',
        message: 'Desculpa, ocorreu um erro no servidor.',
      }),
    ]);

    setTimeout(() => {
      window.location.replace(`${HOST_URL}/logout`);
    }, 600);
    return {
      actions: [report.showMessages(messages), session.end()],
    };
  }
  if (r.version === 2) {
    const error = (response || {}).status !== 200;
    return multiplexV2(r, params, error);
  }
  return errorMux(r, params);
}

export default multiplex;
