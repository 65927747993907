/**
 * @module core
 */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ThunkMiddleware from 'redux-thunk';
import stateReducer from './reducer';
import { ENV } from './config';

const store = createStore(
  stateReducer,
  ENV === 'development'
    ? composeWithDevTools(applyMiddleware(ThunkMiddleware))
    : applyMiddleware(ThunkMiddleware),
);

function get() {
  return store;
}

const queries = { get };

export default queries;
