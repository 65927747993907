import { COMBOS_VIEW } from './consts';

function getCombo(state, resource) {
  return state.getIn(['views', COMBOS_VIEW, 'cache', resource, 'data']);
}

function getData(state, view, resource) {
  return state.getIn(['views', view, 'cache', resource, 'data']);
}

function getBody(state, view, resource) {
  return state.getIn(['views', view, 'cache', resource]);
}

function isValid(state, view, resource) {
  return state.getIn(['views', view, 'cache', resource, 'isValid'], false);
}

const queries = {
  getCombo,
  getData,
  isValid,
  getBody,
};

export default queries;
