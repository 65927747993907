import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

// @TODO Update: - esse arquivo se espalha por todo código, isso é ruim
//               - colors.primary != theme.palette.primary.*

const colors = {
  // TODO: REMOVER AS REFERÊNCIAS DESSE CAMPOS.
  primary: blue[500],
  primaryDark: blue[600],
  secondary: red[500],
  secondaryDark: red[600],

  success: green[500],
  successDark: green[600],
  danger: red[500],
  dangerDark: red[600],
  warning: orange[500],
  warningDark: orange[600],
  grey: grey[300],
  greyDark: grey[500],
  contrast: "#FB8C00",
  contrastDark: orange[800],
  typography: "#607D8B",
  blueDark: "#197997",
  blueDark2: "#1F8CAD",
  blueGray: "#263245",
  blueLight: "#09AFDF",
  blueLight2: "#2BADD3",
  veryDarkBlue: "#004A61",
  blueDark3: "#0C6F8D",
};

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0080ad",
      light: "#09AFDF",
      dark: "#197997",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: red[500],
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "rgba(96, 125, 139)",
      secondary: "rgba(96, 125, 139, 0.80)",
      hint: "rgb(176, 190, 197)",
      disabled: "rgb(176, 190, 197)",
    },
  },
  typography: {
    fontFamily: '"Montserrat"',
    letterSpacing: "0",
  },
  overrides: {
    // Typography
    MuiTypography: {
      body1: {
        fontSize: "0.875rem",
      },
      root: {
        color: "#607D8B",
        letterSpacing: "0",
      },
    },
    // Accordion
    MuiAccordionSummary: {
      root: {
        padding: "0px 24px",
      },
      expandIcon: {
        padding: "0px 8px",
      },
    },
    MuiAccordionActions: {
      root: {
        padding: "16px 8px",
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "8px 24px 24px",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        "&$focused": {
          backgroundColor: "transparent",
        },
      },
    },
    // Table
    MuiTableCell: {
      root: {
        padding: "4px 24px 4px 24px",
        fontSize: "0.8125rem",
      },
      paddingCheckbox: {
        width: "auto",
        padding: "0px 12px",
        "&:last-child": {
          paddingLeft: "none",
          paddingRight: "none",
        },
      },
    },
    MuiTableRow: {
      root: {
        height: "48px",
        "&.Mui-selected": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07)",
          },
        },
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: "0.75rem",
      },
      input: {
        fontSize: "0.75rem",
      },
    },
    // ListItem
    MuiListItem: {
      root: {
        paddingTop: "11px",
        paddingBottom: "11px",
      },
    },
    MuiListItemText: {
      root: {
        padding: "0 16px",
        marginTop: "0px",
        marginBottom: "0px",
      },
      inset: {
        paddingLeft: "none",
      },
      multiline: {
        marginTop: "0px",
        marginBottom: "0px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "auto",
        marginRight: "16px",
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: "auto",
      },
    },
    // Paper
    MuiPaper: {
      elevation1: {
        boxShadow:
          " 0px 0.5px 5px 0px rgba(0,0,0,0.2)," +
          " 0px 0.5px 2px 0px rgba(0,0,0,0.14)," +
          " 0px 1px 1px -2px rgba(0,0,0,0.12)",
      },
    },
    // Dialog
    MuiDialog: {
      paperScrollBody: {
        verticalAlign: "top",
      },
      paper: {
        margin: "64px",
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: "initial",
        padding: "0px 24px 24px",
      },
    },
    // Others
    MuiInputLabel: {
      root: { display: "flex" },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.5rem",
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: "auto",
      },
    },
    MuiTab: {
      root: {
        fontSize: "0.8125rem",
        [breakpoints.up("md")]: {
          padding: "6px 24px",
        },
        [breakpoints.down("md")]: {
          padding: "6px 12px",
        },
      },
      wrapper: {
        [breakpoints.up("md")]: {
          fontSize: "13px",
        },
        [breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: "11px",
        paddingBottom: "11px",
      },
    },
    MuiFormLabel: {
      root: {
        display: "flex",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: ".8rem",
      },
    },
    MuiAutocomplete: {
      root: {
        margin: "16px 0px 0px !important",
      },
    },
  },
});

export { theme, colors };
