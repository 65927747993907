import { List, Record, Set } from 'immutable';

const Disposition = {
  INLINE: 'inline',
  DOWNLOAD: 'download',
};

const Pagination = Record({
  limit: 10000,
  offset: 0,
});

const Filter = Record({
  field: null,
  op: null,
  value: null,
});

const Header = Record({
  token: null,
  force: false,
  report: false,
  sort: null, // List(),
  filter: null, // List(),
  include: null, // List(),
  pagination: null, // Map(),
  param: null, // Map(),
  disposition: null, // Disposition
});

const ValidationRequest = Record({
  fields: Set(),
  dispatch: null,
  bypassDefault: false,
});

const Response = Record({
  transform: (x) => x,
  requiredValidations: ValidationRequest(),
  dispatchRaw: List(),
  dispatch: List(),
  dismiss: false,
  effect: false,
  convert: true,
  resource: '',
  page: false,
  view: '',
});

const CacheHit = Record({
  redispatch: false,
});

const Request = Record({
  view: null,
  url: '',
  urlParams: null,
  force: false,
  data: null,
  raw: false,
  transform: (x) => x,
  header: Header(),
  onSuccess: Response(),
  onError: Response(),
  onCacheHit: CacheHit(),
});

const DownloadHeaders = {
  PDF: {
    'X-Report': 1,
    Accept: 'application/pdf',
    'X-Disposition': 'download',
  },
  TEST: {
    Accept: 'application/pdf',
  },
};

DownloadHeaders.PDFWithFilters = (filters) => ({
  ...DownloadHeaders.PDF,
  'X-Filter': JSON.stringify(filters),
});

const DownloadRequest = Record({
  onSuccess: Response(),
  onError: Response(),
  download: true,
  authorize: true,
  urlParams: null,
  filename: null,
  method: 'get',
  headers: null,
  data: null,
  view: null,
  url: '',
});

export {
  ValidationRequest,
  DownloadRequest,
  DownloadHeaders,
  Disposition,
  Pagination,
  Response,
  CacheHit,
  Request,
  Header,
  Filter,
};
