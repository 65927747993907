/**
 * @module core/queries
 */

/**
 * Retorna o `id` do `applet` atualmente montado.
 *
 * @param {Map} state estado do redux.
 */
function getCurrentApplet(state) {
  return state.get('currentApplet').get(-1);
}

/**
 * Retorna o `id` da `view` atualmente montada.
 *
 * @param {Map} state estado do redux.
 */
function getCurrentView(state) {
  return state.get('currentView');
}

/**
 * Retorna o 'id' do `applet` e da `view` atualmente montadas.
 *
 * @param {Map} state estado do redux.
 */
function getCurrentContext(state) {
  return {
    applet: state.get('currentApplet').get(-1),
    view: state.get('currentView'),
  };
}

const queries = { getCurrentApplet, getCurrentView, getCurrentContext };

export default queries;
